@if (item().routerLink) {
<li class="nav-item" [routerLinkActive]="['active']">
	<a class="nav-link" [routerLink]="item().routerLink">
		<common-icon-text [iconStyle]="item().iconStyle" [icon]="item().icon" [text]="item().label"
			spacing="large"></common-icon-text>
	</a>
</li>
} @else if (item().url) {
<li class="nav-item">
	<a [target]="'_blank'" [href]="item().url">
		<common-icon-text [iconStyle]="item().iconStyle" [icon]="item().icon" [text]="item().label"
			spacing="large"></common-icon-text>
	</a>
</li>
}