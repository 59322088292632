<div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
	<div ngbAccordionItem="{{set().label}}" [destroyOnHide]="false">
		<div ngbAccordionHeader>
			<button ngbAccordionButton>
				<common-icon-text [iconStyle]="set().iconStyle" [icon]="set().icon" [text]="set().label"
					spacing="large"></common-icon-text>
			</button>
		</div>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
					@for (menuItem of set().menuItems; track $index;) {
					<dashboard-menu-item-part [item]="menuItem"></dashboard-menu-item-part>
					}
				</ng-template>
			</div>
		</div>
	</div>
</div>