import { Component, computed, inject, input } from '@angular/core';
import { DashboardLayoutService } from '../../../frame/layout/dashboard-layout.service';
import { DashboardGridWidget } from '../../grid/dashboard-grid.widget';
import { GridSetup, GridSize } from '../../grid/interfaces';
import { DashboardContentBorderWidget } from '../content-border';


@Component({
	selector: 'dashboard-grid-content',
	standalone: true,
	imports: [
		DashboardContentBorderWidget,
		DashboardGridWidget,
	],
	templateUrl: './dashboard-grid-content.widget.html',
	styleUrl: './dashboard-grid-content.widget.scss'
})
export class DashboardGridContentWidget<RowT extends { updatedUTC: number }> {

	public readonly layout = inject(DashboardLayoutService);

	public readonly setup = input.required<GridSetup<RowT>>();
	public readonly namedSize = input.required<'PageContent' | 'TabContent'>();
	public readonly rows = input.required<RowT[]>();

	public readonly size = computed(() => {

		const dims = this.layout.dimensions()
		const name = this.namedSize();

		if (name == 'PageContent') {
			const s: GridSize = {
				width: dims.contentAreaWidth,
				height: dims.contentAreaHeight,
				marginTop: 0,
				marginRight: 0,
				marginBottom: 0,
				marginLeft: 0,
			};
			return s;
		}
		else {
			throw new Error('TabContent grid size callcation not implemented yet!')
		}
	});
}
