import { Injectable, computed, signal } from '@angular/core';
import { combineLatest, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LayoutDimensions } from './layout-dimensions';
import { toObservable } from '@angular/core/rxjs-interop';
import {
	BREAKPOINT_LG,
	BREAKPOINT_MD,
	BREAKPOINT_SM,
	CONTENT_PADDING,
	MENU_WIDTH,
	TOOLBAR_HEIGHT
} from './layout-constants';


@Injectable({ providedIn: 'root' })
export class DashboardLayoutService {

	private readonly _menuCollapsed = signal<boolean>(false);
	public readonly menuCollapsed = this._menuCollapsed.asReadonly();

	private readonly _dimensions = signal<LayoutDimensions>(
		this.calcDimensions(window)
	);
	public readonly dimensions = this._dimensions.asReadonly();


	constructor() {

		//
		// Listen for the window resizing
		//
		const resize$ = fromEvent(window, 'resize')
			.pipe(
				startWith({ target: window }),
			);

		combineLatest([resize$, toObservable(this.menuCollapsed)])
			.pipe(
				map(([e, menuCollapsed]) => this.calcDimensions(e.target as Window)),
			)
			.subscribe(d => this._dimensions.set(d));
	}


	public setMenuCollapsed(isCollapsed: boolean) {
		if (this._menuCollapsed() == isCollapsed) return;
		this._menuCollapsed.set(isCollapsed);
	}

	private calcMenuWidth(window: Window): number {
		const w = window.innerWidth;

		if (w < BREAKPOINT_MD || this.menuCollapsed()) return 0;

		if (w >= BREAKPOINT_MD) return MENU_WIDTH;

		return 0;
	}

	/**
	 * Calculate all of the layout sizes. This is called each time the window
	 * is resized of the menu is opened/closed.
	 * @param window The DOM Window object
	 */
	private calcDimensions(window: Window): LayoutDimensions {

		const menuWidth = this.calcMenuWidth(window);

		//
		// The inner values are the browser window size 
		//
		const w = window.innerWidth;
		const h = window.innerHeight;

		//
		// Determine what breakpoint range the window width equates to
		//
		let breakpoint: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

		if (w < BREAKPOINT_SM) breakpoint = 'xs';
		else if (w < BREAKPOINT_MD) breakpoint = 'sm';
		else if (w < BREAKPOINT_LG) breakpoint = 'md';
		else breakpoint = 'lg';

		//
		// These calculations should align with what is in ACS.page.scss
		//
		let d: LayoutDimensions = {
			menuWidth,
			breakpoint,
			screenWidth: window.outerWidth,
			screenHeight: window.outerHeight,
			windowWidth: w,
			windowHeight: h,
			contentAreaWidth: w - menuWidth,
			contentAreaHeight: h - TOOLBAR_HEIGHT,
			contentWidth: w - menuWidth - 2 * CONTENT_PADDING,
			contentHeight: h - TOOLBAR_HEIGHT - 2 * CONTENT_PADDING,
		};


		if (breakpoint == 'sm') { //544 - 767
			d = Object.assign(d, {
				contentAreaWidth: w,
				contentWidth: w - 2 * CONTENT_PADDING,
			});
		}

		return d;
	}
}