import { DashboardSectionMenu } from '@eforall/dashboard';

export const SOFTWARE_MENU: DashboardSectionMenu = {
	label: 'Software',
	items: [
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-grid-2-plus',
			label: 'Apps',
			routerLink: '/software',
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-person-circle-check',
			label: 'App Users',
			routerLink: '/software-users',
		},
	]
}