import { Component, inject } from '@angular/core';
import { DashboardLayoutService } from '../../../frame/layout/dashboard-layout.service';
import { DashboardContentBorderWidget } from '../content-border';


@Component({
	selector: 'dashboard-scrollable-content',
	standalone: true,
	imports: [DashboardContentBorderWidget],
	templateUrl: './dashboard-scrollable-content.widget.html',
	styleUrl: './dashboard-scrollable-content.widget.scss'
})


export class DashboardScrollableContentWidget {

	public layout = inject(DashboardLayoutService);

}


