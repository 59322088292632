import { Component } from '@angular/core';
import { DashboardScrollableContentWidget } from '@eforall/dashboard';

@Component({
	selector: 'page-3-page',
	standalone: true,
	imports: [DashboardScrollableContentWidget],
	templateUrl: './page-3.page.html'
})

export class Page3Page {


}