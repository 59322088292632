<dashboard-scrollable-content>
	<h4>Organizational Roadmap</h4>

	<kendo-gantt [style.height.px]="800" [data]="data" [hasChildren]="hasChildren" [fetchChildren]="fetchChildren"
		[dependencies]="dependencies" kendoGanttExpandable [initiallyExpanded]="true"
		[timelinePaneOptions]="{ size: '85%' }" [taskClass]="taskClass">
		<kendo-gantt-column field="title" title="Project" [width]="200" [expandable]="true"></kendo-gantt-column>
		<kendo-gantt-timeline-year-view [slotWidth]="50"></kendo-gantt-timeline-year-view>
	</kendo-gantt>

</dashboard-scrollable-content>