import { Department } from "@app-interfaces";
import { UtilityService } from '@eforall/common';
import { GridColumn, buildColumn } from '@eforall/dashboard';


export function getColumns(util: UtilityService) {

	const columns: GridColumn<Department, unknown>[] = [
		buildColumn(util, 'Id', row => row.departmentId, { header: 'departmentId' }),
		buildColumn(util, 'Text', row => row.name, { header: 'Department' }),
		buildColumn(util, 'Text', row => row.head.fullName, { header: 'Head' }),
		buildColumn(util, 'Phone', row => row.head.mobilePhone, { header: 'Phone' }),
		buildColumn(util, 'Text', row => row.head.orgUnitPath, { header: 'Org Unit' }),
		buildColumn(util, 'Number', row => row.staffCount, { header: 'Staff', headerTooltip: 'Count of Staff in this Department' }),
		buildColumn(util, 'Percent', row => row.staffPercent, { header: 'Staff %', headerTooltip: 'Percentage of Staff in this Department' }),
		buildColumn(util, 'DateTime', row => util.date.fromUTC(row.updatedUTC), { hidden: true, header: 'Updated' }),
	];


	return columns;

}