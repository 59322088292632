<div class="offcanvas-header">

	<div class="menu-header" routerLink='/' (click)="activeOffcanvas.dismiss()">
		<dashboard-logo-part></dashboard-logo-part>
	</div>
	<button type="button" class="btn-close text-reset" aria-label="Close"
		(click)="activeOffcanvas.dismiss('Cross click')"></button>
</div>
<div class="offcanvas-body">
	<nav>
		<div class="navbar-content">
			<ul class="nav menu-inner-navbar" (click)='$event.stopPropagation()'>
				@for (group of frame.config()?.menu; track group) {
				<div class="menu-group">
					<li class="nav-item menu-caption">
						<label>{{ group.label }}</label>
					</li>
					@for (item of group.items; track item) {
					@if (item.kind === 'set'){
					<dashboard-menu-accordion-part [set]="item"></dashboard-menu-accordion-part>
					}
					@else if (item.kind === 'menuItem'){
					<dashboard-menu-item-part [item]="item"></dashboard-menu-item-part>
					}
					}
				</div>
				}
			</ul>
		</div>
	</nav>
</div>