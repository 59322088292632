<div class="modal-header">
	<i class='fa-solid {{icon()}} me-1'></i>
	<h4 class="modal-title">{{header()}}</h4>
	<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closed.emit(undefined)"></button>
</div>
<div class="modal-body">
	<ng-content></ng-content>
</div>
<div class="modal-footer">

	@if(actions().length) {
	<common-button-row>
		@for(action of actions(); track action){

		@if(action.visible) {

		@if(action.linkType === "href" && action.download){
		<a class='btn {{action.cssClass}}' [class.disabled]='handlingClick() || !action.enabled' (click)='click(action)'
			[href]='action.url' [download]='action.download + ".tsv"' target="_blank">
			{{action.label}}
		</a>
		}


		@if(action.linkType === "href" && !action.download){
		<a class='btn {{action.cssClass}}' [class.disabled]='handlingClick() || !action.enabled' (click)='click(action)'
			[href]='action.url' target="_blank">
			{{action.label}}
		</a>
		}


		@if(action.linkType !== "href"){
		<button class='btn {{action.cssClass}}' (click)='click(action)' [disabled]='handlingClick() || !action.enabled'>
			{{action.label}}
		</button>
		}

		}
		}
	</common-button-row>
	}
</div>