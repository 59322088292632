import { JobTrack } from "@app-interfaces";
import { GridAction } from '@eforall/dashboard';


export function getActions() {

	const actions: GridAction<JobTrack>[] = [
		{
			iconClass: 'fa-regular fa-plus',
			label: 'Add Job Track',
			isEnabled: rows => !!rows.length,
			execute: async rows => { console.log(`Action clicked!`, rows) }
		},
	];

	return actions;
}