import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppService } from '../../services';


@Component({
	selector: 'app-content-page',
	standalone: true,
	imports: [
		RouterOutlet,
	],
	templateUrl: './app-content.page.html',
})
export class AppContentPage {

	public app = inject(AppService);

}
