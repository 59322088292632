import { Signal, computed, effect, signal } from "@angular/core";
import { SelectableSettings } from "@progress/kendo-angular-grid";
import { GridSetup } from "../../interfaces";
import { stringArrayEquality } from "../equality/string-array-equality";



export class GridSelectionManager<RowT extends { updatedUTC: number }> {

	public readonly selectedKeysModel = signal<string[]>([]);
	public readonly selectedKeys = computed(() => this.selectedKeysModel(), { equal: stringArrayEquality });


	constructor(
		private readonly setup: Signal<GridSetup<RowT>>,
		private readonly rows: Signal<RowT[]>,
	) {
		effect(() => {
			console.log(`selectedKeys()`, this.selectedKeys());
		});
	}

	public readonly selectedRows = computed<RowT[]>(() => {
		const getRowKey = this.setup().getRowKey;
		const keys = this.selectedKeys();
		const rows = this.rows().filter(row => keys.includes(getRowKey(row)))
		return rows;
	});

	public keyOfSingleRowSelected = computed(() => this.selectedKeys().length !== 1 ? undefined : this.selectedKeys()[0]);  // used for toggling a single selected row


	public settings = computed<SelectableSettings>(() => ({
		checkboxOnly: false,
		drag: true,
		mode: this.setup().multiselect ? 'multiple' : 'single',
	}));


	public set(selectedRowIds: string[]) {
		this.selectedKeysModel.set(selectedRowIds);
	}


	public clear() {
		this.selectedKeysModel.set([]);
	}

}