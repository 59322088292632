import { Component, OnDestroy, OnInit, OutputRefSubscription, input, viewChild } from '@angular/core';
import { ContextMenuComponent, ContextMenuSelectEvent, MenusModule } from '@progress/kendo-angular-menu';
import { GridAction, GridController } from '../';


@Component({
	selector: 'dashboard-grid-menu',
	standalone: true,
	imports: [MenusModule],
	templateUrl: './dashboard-grid-menu.part.html',
	styleUrls: ['dashboard-grid-menu.part.scss'],
})
export class DashboardGridMenuPart<RowT extends { updatedUTC: number; }> implements OnInit, OnDestroy {

	public contextMenu = viewChild.required(ContextMenuComponent);
	public grid = input.required<GridController<RowT>>();
	private sub?: OutputRefSubscription;


	ngOnInit() {
		this.sub = this.grid().contextMenu.subscribe(menu => {
			if (menu) this.contextMenu().show(menu)
		});
	}


	ngOnDestroy() {
		this.sub?.unsubscribe();
	}


	public async onActionItemClick(event: ContextMenuSelectEvent) {
		const action: GridAction<RowT> = event.item;
		const rows = this.grid().selection.selectedRows();
		if (!action.isEnabled(rows)) return;
		await action.execute(rows);
	}

}