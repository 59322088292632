import { AsyncPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService, EnvironmentService, ErrorService, SpinnerService, UrlService, UtilityService } from '@eforall/common';
import { DashboardErrorPart, DashboardFrame, DashboardFrameService, DashboardLayoutService, DashboardSpinnerWidget } from '@eforall/dashboard';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments';
import { AppService } from '../../services';
import { getDashboardFrameConfig } from './dashboard-frame-config';


@Component({
	selector: 'bootstrap-page',
	standalone: true,
	imports: [
		AsyncPipe,
		DashboardErrorPart,
		DashboardFrame,
		DashboardSpinnerWidget,
		JsonPipe,
		NgbModule,
		RouterOutlet,
	],
	templateUrl: `bootstrap.page.html`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BootstrapPage {

	public spinnerService = inject(SpinnerService);
	public errorService = inject(ErrorService);

	private env = inject(EnvironmentService);
	private auth = inject(AuthService);
	private router = inject(Router);
	private util = inject(UtilityService);
	private urlService = inject(UrlService);
	private frame = inject(DashboardFrameService);
	private layout = inject(DashboardLayoutService);
	private ngbTooltipConfig = inject(NgbTooltipConfig);

	// private pwa = inject(PwaService);

	public app = inject(AppService);


	constructor() {

		this.auth.setData(
			this.app.loadData.bind(this.app),
			this.app.clearData.bind(this.app),
		);

		this.env.setEnvironment(environment);

		this.init();
		this.setNgbTooltipConfig();
	}

	async init() {
		this.frame.initialize(await getDashboardFrameConfig(this.app));
	}

	setNgbTooltipConfig() {
		this.ngbTooltipConfig.triggers = 'hover';
		this.ngbTooltipConfig.openDelay = 2000;
		this.ngbTooltipConfig.closeDelay = 100;
	}
}
