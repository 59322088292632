import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogAction } from '../dialog-action';
import { DialogFrame } from '../frame/dialog.frame';

export interface MessageDialogData { message: string, buttonText?: string, bullets?: string[] }


@Component({
	selector: 'message-dialog',
	standalone: true,
	imports: [DialogFrame, CommonModule],
	templateUrl: './message.dialog.html',
})

export class MessageDialog {

	activeModal = inject(NgbActiveModal);

	public readonly data = input<MessageDialogData>({ message: '', bullets: [] });


	public actions = computed<DialogAction[]>(() => {
		return [
			{
				id: 'okay',
				enabled: true,
				visible: true,
				label: this.data().buttonText ?? 'Okay',
				cssClass: 'btn-standard',
				linkType: "no-action",
				willCloseDialog: true,
			}
		];
	});


}
