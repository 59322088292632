<div class="dashboard-error-generic-data-part container-fluid" [style.background]='background'>
	<div class="row justify-content-center">
		<div class="col-10 col-sm-8 col-md-6 col-lg-4">
			<div class="card">
				<div class="card-header text-center">{{activity}}</div>
				<div class="card-body text-center">
					<h3>{{name}}</h3>
					<p></p>
					<p class="card-text">{{message}}</p>
					<p></p>

					@if(name === "Failed to Connect"){
					<div class="help-text">
						<!-- <me-label key='md:Failed To Connect Guidance'></me-label> -->
						This error has started happening recently. We are working to resolve it. In the meantime, please
						wait a few minutes,
						refresh your browser, and try again.
					</div>
					}
					<div class="help-text">
						<!-- <me-label key='md:Data Error Guidance'></me-label> -->
						This error has been automatically communicated to The Technology Team. We will use the
						information sent to attempt to
						resolve the issue for the next release. Sorry for the inconvenience!
					</div>

					<code class="text-left">{{stack}}</code>
					<div class='btn-container'>
						<button class='btn btn-standard' (click)='goBack()'>
							<!-- <me-label key='OK'></me-label> -->
							OK
						</button>
						<button class='btn btn-info' (click)='goToDashboard()'>
							<!-- <me-label key='Dashboard'></me-label> -->
							Dashboard
						</button>
						<button [hidden]='!canlogout' class='btn btn-danger' (click)='logout()'>
							<!-- <me-label key='Log out'></me-label> -->
							Log out
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>