<dashboard-scrollable-content>
	<h2>Example Page</h2>
	<div>
		<h6>Url Service</h6>
		<p>
			<button class="btn btn-secondary" (click)="getBaseUrl()">Get baseUrl</button>
		</p>
	</div>
	<div>
		<h6>Page Spinner</h6>
		<p>
			<common-button-row>
				<button class="btn btn-secondary" (click)='setSpinner(2)'>2 second spinner</button>
				<button class="btn btn-secondary" (click)='setSpinner(30)'>30 second spinner</button>
				<button class="btn btn-secondary" (click)='setSpinner(0)'>Force spinner on</button>
				<button class="btn btn-secondary" (click)='spinnerService.forceOff()'>Force spinner off</button>
				<button class="btn btn-secondary" (click)='spinnerService.writeLogsToConsole()'>Write spinner logs to
					console</button>
			</common-button-row>
		</p>
	</div>
	<div>
		<h6>Error</h6>
		<p>
			<button class="btn btn-secondary" (click)='goToErrorPage()'>Error Page</button>
		</p>
	</div>
	<div>
		<h6>Dialog</h6>
		<p>
			<common-button-row>
				<button class="btn btn-secondary" (click)='confirm()'>Confirm Dialog</button>
				<button class="btn btn-secondary" (click)='openMessage()'>Message Dialog</button>
			</common-button-row>
		</p>
	</div>

	<div>
		<h6>Icon Text</h6>
		<div style="display:inline-block; max-width:500px; border:solid 1px #999; background:#DDD; padding: 0.3em;">
			<common-icon-text text="Some Text" icon="fa-user" style="font-size: 1.0em;"></common-icon-text>
			<common-icon-text text="Some Text" icon="fa-user" style="font-size: 1.3em;"></common-icon-text>
			<common-icon-text text="Some Text" icon="fa-user" style="font-size: 1.6em;"></common-icon-text>
		</div>
	</div>
</dashboard-scrollable-content>