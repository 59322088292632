import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ErrorService } from '@eforall/common';
import { DashboardErrorGenericDataPart } from './error-data-generic/error-generic-data.part';
import { DashboardErrorUnverifiedEmailPart } from './error-unverified-email/error-unverified-email.part';


@Component({
	selector: 'dashboard-error-part',
	standalone: true,
	imports: [CommonModule, DashboardErrorGenericDataPart, DashboardErrorUnverifiedEmailPart],
	templateUrl: './error.part.html',
})
export class DashboardErrorPart {
	public errorService = inject(ErrorService);
}