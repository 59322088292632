import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';


@Component({
	selector: 'dashboard-error-unauthorized-page',
	standalone: true,
	templateUrl: './error-unauthorized.page.html',
	styleUrls: ['./error-unauthorized.page.scss']
})
export class DashboardErrorUnauthorizedPage {
	private router = inject(Router);

	goToDashboard(): void {
		this.router.navigate(['/']);
	}
}