<dialog-frame header="Confirm" [actions]="actions()" icon="fa-question-circle" (closed)="activeModal.close($event)">
	
	<p>{{ data().message }}</p>

	@if(data().bullets?.length) {
	<div class="m-1">
		<ul>
			@for(bullet of data().bullets; track bullet){
			<li>{{ bullet }}</li>
			}
		</ul>
	</div>
	}
</dialog-frame>