import { EventEmitter, Signal } from "@angular/core";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { GridFlatRow, GridSetup } from "../../interfaces";
import { GridSelectionManager } from "./grid-selection-manager";

export interface CtrlAltShift { alt: boolean, ctrl: boolean, shift: boolean }


export class GridClickManager<RowT extends { updatedUTC: number }> {

	private _lastClickTime = 0;


	constructor(
		private readonly setup: Signal<GridSetup<RowT>>,
		private readonly rows: Signal<RowT[]>,
		private readonly selection: GridSelectionManager<RowT>,
		private readonly doubleClick: EventEmitter<{ keys: CtrlAltShift, row: RowT }>,
		private readonly contextMenu: EventEmitter<{ left: number, top: number } | undefined>,
	) {
	}

	public onCellClick(event: CellClickEvent) {

		const oe = event.originalEvent ?? {};
		const keys: CtrlAltShift = {
			alt: !!oe.altKey,
			ctrl: !!oe.ctrlKey,
			shift: !!oe.shiftKey,
		};

		if (event.type == 'contextmenu') {

			if (keys.ctrl) return;

			this.showContextMenu(event);

			event.originalEvent.preventDefault();
			return false;
		}

		const clickTime = Date.now();
		if (clickTime - this._lastClickTime < 500) {
			this._lastClickTime = 0;

			setTimeout(() => {
				const getRowKey = this.setup().getRowKey;
				const flatRow: GridFlatRow<RowT> = event.dataItem;
				const rowKey = getRowKey(flatRow.row);
				const row = this.rows().find(row => getRowKey(row) == rowKey);
				this.selection.set([rowKey]);
				if (row) this.doubleClick.emit({ keys, row });
			});
		}
		else {
			this._lastClickTime = clickTime;

			setTimeout(() => {
				// If only one row is selected and selectionChange was not fired before this event,
				// setting keyOfSingleFileSelected, then we know it is a follow-on click and toggle
				// the selection of the single row off.
				const removeSelection = this.selection.selectedKeys().length == 1 && !this.selection.keyOfSingleRowSelected();
				if (removeSelection) this.selection.set([]);
			});
		}
		return;
	}


	/**
	 * Display a menu with actions enabled based on the current selection of rows
	 */
	private showContextMenu(event: CellClickEvent) {

		setTimeout(() => {

			//
			// Rerun the action enablers
			//

			const row: GridFlatRow<RowT> = event.dataItem;

			if (this.selection.settings().mode == 'single' || this.selection.selectedKeys().length <= 1) {
				this.selection.set([row.rowKey]);
				this.contextMenu.emit({ left: event.originalEvent.pageX, top: event.originalEvent.pageY });
			}
			else {
				//
				// If we get here, the mode is 'multiple' AND at least 2 rows are selected.
				// We only show the context menu if they right-clicked on one of the selected rows
				//
				if (this.selection.selectedKeys().includes(row.rowKey)) {
					this.contextMenu.emit({ left: event.originalEvent.pageX, top: event.originalEvent.pageY });
				}
				else {
					this.contextMenu.emit(undefined);
				}
			}

		});
	}
}