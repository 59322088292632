import { Component, inject } from '@angular/core';
import { DashboardLayoutService } from '../../frame/layout/dashboard-layout.service';

@Component({
	selector: 'dashboard-content-border',
	standalone: true,
	template: `<div class='dashboard-content-border' [style.width.px]="layout.dimensions().contentAreaWidth" [style.height.px] = "layout.dimensions().contentAreaHeight"></div>`,
	styles: `
	.dashboard-content-border {
		display: inline-block;
		position: absolute;
		border-left: 1px solid rgba(0, 0, 0, .2);
		border-top: 1px solid rgba(0, 0, 0, .2);
		box-shadow: -.1em -.1em .1em rgba(0, 0, 0, 0.1);
		right: 0;
		bottom: 0;
		z-index: 1000;
		pointer-events: none;
	}`,
})
export class DashboardContentBorderWidget {
	readonly layout = inject(DashboardLayoutService);
}
