import { FuncData } from '@interfaces';

export const EMPTY_DATA: FuncData = {

	asOfUtc: 0,

	//
	// Staff Data
	//
	departments: [],
	jobRoles: [],
	jobTracks: [],
	locations: [],
	pronouns: [],
	staff: [],
	
	//
	// Knowledge Data
	//
	messages: [],
	software: [],
	
};