import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonButtonRowWidget, CommonIconTextWidget, ENGLISH, EnvironmentService, SpinnerService, UrlService } from '@eforall/common';
import { DashboardScrollableContentWidget, DialogService } from '@eforall/dashboard';


@Component({
	selector: 'tech-examples-page',
	standalone: true,
	imports: [
		CommonButtonRowWidget,
		CommonIconTextWidget,
		DashboardScrollableContentWidget,
	],
	templateUrl: './tech-examples.page.html'
})
export class TechExamplePage {

	private env = inject(EnvironmentService);
	private urlService = inject(UrlService);
	public spinnerService = inject(SpinnerService);
	public dialogService = inject(DialogService);
	private router = inject(Router);


	getBaseUrl() {
		console.log(this.urlService.getBaseUrl(this.env.getEnvironment(), ENGLISH));
	}


	goToErrorPage() {
		this.router.navigate(['error-page']);
	}


	async setSpinner(seconds: number) {

		const key = seconds > 0 ? `${seconds} Second Spinner` : `Constant Spinner`;
		this.spinnerService.addSpinner(key, key);

		if (seconds > 0) {
			setTimeout(() => { this.spinnerService.removeSpinner(key); }, seconds * 1000);
		}

	}


	async removeSpinner() {
		const desc = `Example Spinner`;
		this.spinnerService.removeSpinner(desc);
	}

	async confirm() {
		const yes = await this.dialogService.confirm('Is the confirm dialog working?');
		if (yes) alert('Yes');
	}

	async openMessage() {
		const action = await this.dialogService.showMessage('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.');
		if (action) alert(action.id);
	}

}