import { Component, inject } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { DashboardGridContentWidget } from '@eforall/dashboard';
import { AppService } from 'services';
import { getSetup } from './grid/get-setup';


@Component({
	selector: 'messages-page',
	imports: [DashboardGridContentWidget],
	standalone: true,
	templateUrl: './messages.page.html'
})

export class MessagesPage {

	public readonly app = inject(AppService);
	private readonly util = inject(UtilityService);

	public setup = getSetup(this.util);

}