import { GridFlatRow } from "../../interfaces";

export function flatRowArrayEquality<RowT extends { updatedUTC: number }>(a: GridFlatRow<RowT>[], b: GridFlatRow<RowT>[]) {

	if (a === b) return true;
	if (a == undefined && b == undefined) return true;
	if (a == undefined || b == undefined) return false;
	if (a.length !== b.length) return false;
	if (a.length == 0) return true;

	//
	// Do the quick checks
	//
	for (let r = 0; r < a.length; r++) {
		if (a[r] === b[r]) continue;
		if (a[r]?.rowKey !== b[r]?.rowKey) return false;
		if (a[r]?.updatedUTC !== b[r]?.updatedUTC) return false;
	}

	//
	// If we get here, then the structure is the same.
	// So, we need to check each of the column values.
	//
	const numCols = a[0]?.numCols ?? 0;	// We check the length up above

	for (let r = 0; r < a.length; r++) {
		for (let c = 0; c < numCols; c++) {
			const field = `col` + c;
			const ar = a[r];
			const av = ar ? ar[field] : undefined;
			const br = b[r];
			const bv = br ? br[field] : undefined;

			if (av !== bv) return false;
		}
	}

	return true;
}