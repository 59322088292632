import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService, CommonIconTextWidget, EnvironmentService } from '@eforall/common';
import { NgbDropdownModule, NgbOffcanvas, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardFrameService } from '../dashboard-frame.service';
import { DashboardLayoutService } from '../layout/dashboard-layout.service';
import { BREAKPOINT_MD } from '../layout/layout-constants';
import { DashboardLogoPart } from '../logo/dashboard-logo.part';
import { DashboardMenuOffcanvasPart } from '../menu/menu-offcanvas/dashboard-menu-offcanvas.part';


@Component({
	selector: 'dashboard-toolbar-part',
	standalone: true,
	imports: [NgbDropdownModule, NgbTooltipModule, DashboardLogoPart, CommonIconTextWidget, RouterModule, CommonModule, DashboardMenuOffcanvasPart],
	templateUrl: './dashboard-toolbar.part.html',
	styleUrl: './dashboard-toolbar.part.scss'
})


export class DashboardToolbarPart {
	public frame = inject(DashboardFrameService);
	public router = inject(Router);
	public authService = inject(AuthService);
	public envService = inject(EnvironmentService);
	private offcanvasService = inject(NgbOffcanvas);
	public layout = inject(DashboardLayoutService);
	BREAKPOINT_MD = BREAKPOINT_MD;


	toggleMenu() {
		if (this.layout.dimensions().windowWidth <= this.BREAKPOINT_MD) {
			this.open();
		}
		else this.layout.setMenuCollapsed(!this.layout.menuCollapsed());

	}

	openSearch() {
		window.open('https://cloudsearch.google.com/', 'cloud-search-google');
	}

	openAlerts() {
		console.log('alerts');
	}

	async open() {
		const offcanvasRef = this.offcanvasService.open(DashboardMenuOffcanvasPart);
	}

}
