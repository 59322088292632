import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthService, ErrorService } from '@eforall/common';
import firebase from 'firebase/compat/app';


const FIVE_MINS = 5 * 60 * 1000;

@Component({
	selector: 'dashboard-error-unverified-email-part',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './error-unverified-email.part.html',
	styleUrls: ['./error-unverified-email.part.scss']
})
export class DashboardErrorUnverifiedEmailPart implements OnInit, OnDestroy {

	public auth = inject(AuthService);
	private errorService = inject(ErrorService);

	//
	// NOTE: This component has hard-coded English and Spanish because the label service will not
	// be able to retrieve the cache of labels if the user's email address is not verified. When
	// an email is not verified, every function call will error and result in this component being
	// displayed.  That includes the call to read the labels.
	//

	public passwordUser = false; // true if user logged in with email/password

	public welcomeMessage: string = '';
	public passwordNotVerified1: string = '';
	public passwordNotVerified2: string = '';
	public emailVerificationMessage: string = '';
	public checkSpamFolder: string = '';
	public goToLoginScreen: string = '';
	public resendEmail: string = '';
	private timeoutId?: number;


	ngOnInit() {

		const user = firebase.auth().currentUser;

		//
		// Set up the text to be displayed
		//
		// if (window.location.hostname.includes('eparatodos')) this.setSpanish(user.displayName);
		// else 
		this.setEnglish(user?.displayName || '');

		this.passwordUser = (!!user?.providerData[0] && user?.providerData[0].providerId == 'password');
		if (this.passwordUser) this.sendEmail();


		//
		// This page instructs people to go check their email for a link. That link
		// opens up the app in a new tab, which if all works as expected, will validate
		// their email and send them to the login screen.  If they come back to the 
		// original tab, it would be weird to see the same original message and a
		// button to resend the code.  So, after five minutes has elapsed, this page
		// automatically signs the user out and redirects to the login screen.
		//
		this.timeoutId = window.setTimeout(this.signout.bind(this), FIVE_MINS);

	}

	ngOnDestroy() {
		if (this.timeoutId) {
			window.clearTimeout(this.timeoutId);
			this.timeoutId = undefined;
		}
	}


	public signout() {
		this.errorService.clearError();
		this.auth.signOut();
	}


	public sendEmail() {
		window.clearTimeout(this.timeoutId);
		this.timeoutId = window.setTimeout(this.signout.bind(this), FIVE_MINS);

		//
		// Firebase will send an email with a link to verify the email address. The template is defined in
		// the Firebase console. Unfortunately, at least for now, it can only be sent in English.
		//
		// firebase.auth().currentUser?.sendEmailVerification({ url: this.urlService.baseUrl + '/signout' });
		//firebase.auth().currentUser.sendEmailVerification({ url: this.urlService.getBaseUrl(LanguageId.English) + '/signout' });
	}


	private setEnglish(displayName: string | undefined) {
		this.welcomeMessage = `Welcome, ${displayName}`;
		this.passwordNotVerified1 = 'The login provider reported that your email address has not been verified. We cannot use this login until you verify the email with them.';
		this.passwordNotVerified2 = 'Please log out and try again, or use a different login.';
		this.emailVerificationMessage = 'An email was sent to your email address to verify it. Follow the instructions in that email and then log in again.';
		this.checkSpamFolder = 'Check your spam folder if needed.';
		this.goToLoginScreen = 'Go to Login Screen';
		this.resendEmail = 'Resend Email';
	}


	private setSpanish(displayName: string) {
		this.welcomeMessage = `Bienvenido, ${displayName}`;
		this.passwordNotVerified1 = 'El proveedor de inicio de sesión informó que su dirección de correo electrónico no ha sido verificada. No podemos utilizar este inicio de sesión hasta que verifique el correo electrónico con ellos.';
		this.passwordNotVerified2 = 'Por favor, cierre la sesión y vuelva a intentarlo, o use un inicio de sesión diferente.';
		this.emailVerificationMessage = 'Se envió un correo electrónico a su dirección de correo electrónico para verificarlo. Siga las instrucciones en ese correo electrónico y luego inicie sesión nuevamente.';
		this.checkSpamFolder = 'Revise su carpeta de spam si es necesario.';
		this.goToLoginScreen = 'Ir a inicio de sesión';
		this.resendEmail = 'Reenviar email';
	}

}