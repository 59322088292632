import { GridButton } from '@eforall/dashboard';


export function getButtons() {

	const buttons: GridButton[] = [
		{
			iconClass: 'fa-plus',
			label: 'Add Message',
			execute: () => { console.log('Add Message Clicked!') }
		},
	];

	return buttons;
}