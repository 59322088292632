import { Component } from '@angular/core';
import { DashboardScrollableContentWidget } from '../../../widgets/content';


@Component({
	selector: 'error-404-page',
	standalone: true,
	imports: [
		DashboardScrollableContentWidget,
	],
	templateUrl: './error-404.page.html',
	styles: `
	dashboard-scrollable-content {
		.error-404 {
			text-align: center;
			color: darkred;
			margin-top: 3em;
		}
	}
	`
})
export class DashboardError404Page {
}
