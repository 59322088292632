import { Message, Software, Staff, Department } from '@app-interfaces';
import { FuncData } from '@interfaces';


export function buildKnowledgeData(
	data: FuncData,
	staffById: Record<number, Staff>,
	departmentById: Record<number, Department>,
) {

	const messages: Message[] = data.messages.map(m => ({
		...m,
		createdBy: staffById[m.createdByStaffId]!,
	}));

	const software: Software[] = data.software.map(s => ({
		...s,
		department: departmentById[s.departmentId]!,
		owner: staffById[s.ownerStaffId]!,
	}));


	return {
		messages,
		software,
	};
}