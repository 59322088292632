<div class="dashboard-grid-buttons">
	<div>
		<div>

			@for(btn of setup().buttons; track $index;){
			<button kendobutton type="button" (click)="btn.execute()"
				class="k-button k-button-md k-rounded-md k-button-flat-base k-button-flat" [title]='btn.label'><i
					class='fa-fw far {{btn.iconClass}}'></i></button>
			}

			<button kendobutton type="button" (click)='changeFilter()'
				class="k-button k-button-md k-rounded-md k-button-flat-base k-button-flat" title='Filter'><i
					[class]='filterButtonClass()'></i></button>

			<kendo-dropdownbutton [data]="setup().actions" fillMode="flat" (itemClick)='onActionItemClick($event)'
				title='Actions'>

				<i class='fa-fw far fa-ellipsis-vertical'></i>

				<ng-template kendoDropDownButtonItemTemplate let-dataItem>
					<div class='dashboard-grid-action'
						[class.dashboard-grid-action-not-enabled]='!isActionEnabled(dataItem)'
						[class.dashboard-grid-menu-separated]='dataItem.separated'>
						<i class="{{ dataItem.iconClass }} fa-fw"></i>
						<span style='padding: 0 0.5em'>{{ dataItem.label}}</span>
					</div>
				</ng-template>

			</kendo-dropdownbutton>

		</div>
		<div><small>
				{{counts()}}
				@if(rows() && rows().length === 1){<ng-container>{{ setup().rowName.singular }}</ng-container>}
				@if(rows() && rows().length !== 1){<ng-container>{{ setup().rowName.plural }}</ng-container>}
			</small></div>
	</div>
</div>

<kendo-grid #theGrid class='dashboard-grid' [columnMenu]='true' [filterable]="'menu'" [navigable]='true'
	[pageable]='true' [resizable]='true' [scrollable]="'virtual'" [groupable]="grid.groupable.settings()"
	[reorderable]='true' [selectable]='grid.selection.settings()' [sortable]="true" [filter]='grid.state.get().filter'
	[group]='grid.state.get().group' [pageSize]='grid.state.get().take' [skip]='grid.state.get().skip'
	[sort]='grid.state.get().sort' [kendoGridGroupBinding]="grid.flatRows.all()" (cellClick)="grid.click.onCellClick($event)"
	(dataStateChange)='grid.state.onChange($event)' (pageChange)="grid.state.onPageChange($event)"
	[kendoGridSelectBy]="selectBy" [(selectedKeys)]="grid.selection.selectedKeysModel" [height]='size().height'
	[rowHeight]='24' [ngStyle]="gridStyle()" [loading]='loading()'>

	@if(setup().multiselect){
	<kendo-grid-checkbox-column [showSelectAll]="true" [columnMenu]="false" [width]="30">
	</kendo-grid-checkbox-column>
	}

	@for(col of setup().columns; track col.flatRowField;){

	<kendo-grid-column [title]="getHeader(col)" [width]="col.width" [field]="col.flatRowField"
		[filterable]="!!col.filterType" [filter]="'text'" [hidden]='col.hidden!' [columnMenu]="!col.noColumnMenu">

		<ng-template kendoGridCellTemplate let-row>

			<dashboard-grid-cell [setup]="setup()" [column]='col' [flatRow]='row'>
				<ng-container>{{ renderCell(row, col, util) }}</ng-container>
			</dashboard-grid-cell>

		</ng-template>

		<ng-template kendoGridHeaderTemplate let-column>
			<div [title]="getHeaderTooltip(column)" kendoTooltip position="top"
				style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{ column.title }}</div>
		</ng-template>

		<ng-template kendoGridGroupHeaderTemplate let-group="group" let-aggregates="aggregates">
			{{ getColumnName(group) }} <strong>{{ formatGroupValue(group) }}</strong> - {{ getGroupCount(group) }}
		</ng-template>

		<ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
			@if(col.filterType==='text'){
			<kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
			</kendo-grid-string-filter-menu>
			}
			@if(col.filterType==='numeric'){
			<kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
			</kendo-grid-numeric-filter-menu>
			}
			@if(col.filterType==='date'){
			<kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
			</kendo-grid-date-filter-menu>
			}
			@if(col.filterType==='boolean'){
			<kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
			</kendo-grid-boolean-filter-menu>
			}
		</ng-template>

	</kendo-grid-column>
	}
	<ng-template kendoGridNoRecordsTemplate>
		@if(grid.filters.hasFilters){
		<div style='margin: 1em 0;'>
			<button class="btn" (click)='grid.filters.clearFilters()'>Clear Filters</button>
		</div>
		}
		@else {
		<div style='margin: 2em 0; font-style: italic;'> 0 {{setup().rowName.plural}} </div>
		}
	</ng-template>

</kendo-grid>

<dashboard-grid-menu [grid]="grid"></dashboard-grid-menu>