/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Injectable, Directive, Component, Input, ContentChild, EventEmitter, Output, isDevMode, forwardRef, ViewChildren, HostListener, HostBinding, ContentChildren, NgModule } from '@angular/core';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
import { Keys } from '@progress/kendo-angular-common';
import { xIcon, filterAddGroupIcon, filterAddExpressionIcon } from '@progress/kendo-svg-icons';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i6 from '@progress/kendo-angular-buttons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import * as i2$1 from '@progress/kendo-angular-dropdowns';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import * as i2 from '@progress/kendo-angular-inputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import * as i2$2 from '@progress/kendo-angular-dateinputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';

/**
 * @hidden
 */
function FilterExpressionOperatorsComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.getOperator(dataItem_r1.value));
  }
}
function FilterExpressionOperatorsComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.getOperator(dataItem_r3.value));
  }
}
const _c0 = (a0, a1) => ({
  templateRef: a0,
  $implicit: a1
});
function FilterExpressionComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 9)(1, "kendo-filter-expression-operators", 10);
    i0.ɵɵlistener("valueChange", function FilterExpressionComponent_div_4_Template_kendo_filter_expression_operators_valueChange_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onOperatorChange($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("currentItem", ctx_r1.currentItem)("operators", ctx_r1.operators)("editorType", ctx_r1.getEditorType());
  }
}
function FilterExpressionComponent_ng_container_6_kendo_filter_text_editor_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "kendo-filter-text-editor", 15);
    i0.ɵɵlistener("valueChange", function FilterExpressionComponent_ng_container_6_kendo_filter_text_editor_1_Template_kendo_filter_text_editor_valueChange_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.valueChange.emit());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("currentItem", ctx_r1.currentItem)("isDisabled", ctx_r1.isEditorDisabled);
  }
}
function FilterExpressionComponent_ng_container_6_kendo_filter_numeric_editor_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "kendo-filter-numeric-editor", 16);
    i0.ɵɵlistener("valueChange", function FilterExpressionComponent_ng_container_6_kendo_filter_numeric_editor_2_Template_kendo_filter_numeric_editor_valueChange_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.valueChange.emit());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("currentItem", ctx_r1.currentItem)("isDisabled", ctx_r1.isEditorDisabled)("format", ctx_r1.numericEditorFormat);
  }
}
function FilterExpressionComponent_ng_container_6_kendo_filter_boolean_editor_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "kendo-filter-boolean-editor", 17);
    i0.ɵɵlistener("valueChange", function FilterExpressionComponent_ng_container_6_kendo_filter_boolean_editor_3_Template_kendo_filter_boolean_editor_valueChange_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.valueChange.emit());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("currentItem", ctx_r1.currentItem);
  }
}
function FilterExpressionComponent_ng_container_6_kendo_filter_date_editor_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "kendo-filter-date-editor", 16);
    i0.ɵɵlistener("valueChange", function FilterExpressionComponent_ng_container_6_kendo_filter_date_editor_4_Template_kendo_filter_date_editor_valueChange_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.valueChange.emit());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("currentItem", ctx_r1.currentItem)("isDisabled", ctx_r1.isEditorDisabled)("format", ctx_r1.dateEditorFormat);
  }
}
function FilterExpressionComponent_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 11);
    i0.ɵɵtemplate(1, FilterExpressionComponent_ng_container_6_kendo_filter_text_editor_1_Template, 1, 2, "kendo-filter-text-editor", 12)(2, FilterExpressionComponent_ng_container_6_kendo_filter_numeric_editor_2_Template, 1, 3, "kendo-filter-numeric-editor", 13)(3, FilterExpressionComponent_ng_container_6_kendo_filter_boolean_editor_3_Template, 1, 1, "kendo-filter-boolean-editor", 14)(4, FilterExpressionComponent_ng_container_6_kendo_filter_date_editor_4_Template, 1, 3, "kendo-filter-date-editor", 13);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngSwitch", ctx_r1.getEditorType());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "string");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "number");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "boolean");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "date");
  }
}
function FilterExpressionComponent_ng_container_7_ng_template_1_Template(rf, ctx) {}
function FilterExpressionComponent_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FilterExpressionComponent_ng_container_7_ng_template_1_Template, 0, 0, "ng-template", 18);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("templateContext", i0.ɵɵpureFunction2(1, _c0, ctx_r1.editorTemplate, ctx_r1.currentItem));
  }
}
const _c1 = (a0, a1) => ({
  "k-group-start": a0,
  "k-group-end": a1
});
function FilterGroupComponent_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵlistener("click", function FilterGroupComponent_button_3_Template_button_click_0_listener() {
      const operator_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.selectedChange(operator_r2.value));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const operator_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c1, operator_r2.value === "and", operator_r2.value === "or"))("selected", ctx_r2.currentItem.logic === operator_r2.value)("title", operator_r2.text);
    i0.ɵɵattribute("aria-pressed", ctx_r2.currentItem.logic === operator_r2.value);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r2.getOperator(operator_r2.value), " ");
  }
}
function FilterGroupComponent_ul_9_ng_container_1_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 12)(1, "kendo-filter-expression", 13);
    i0.ɵɵlistener("valueChange", function FilterGroupComponent_ul_9_ng_container_1_li_1_Template_kendo_filter_expression_valueChange_1_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.handleExpressionValueChange($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    const item_r6 = ctx_r4.$implicit;
    const i_r7 = ctx_r4.index;
    i0.ɵɵadvance();
    i0.ɵɵproperty("currentItem", item_r6)("index", i_r7);
  }
}
function FilterGroupComponent_ul_9_ng_container_1_li_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 12)(1, "kendo-filter-group", 13);
    i0.ɵɵlistener("valueChange", function FilterGroupComponent_ul_9_ng_container_1_li_2_Template_kendo_filter_group_valueChange_1_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.valueChange.emit($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    const item_r6 = ctx_r4.$implicit;
    const i_r7 = ctx_r4.index;
    i0.ɵɵadvance();
    i0.ɵɵproperty("currentItem", item_r6)("index", i_r7);
  }
}
function FilterGroupComponent_ul_9_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FilterGroupComponent_ul_9_ng_container_1_li_1_Template, 2, 2, "li", 11)(2, FilterGroupComponent_ul_9_ng_container_1_li_2_Template, 2, 2, "li", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !item_r6["filters"]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r6["filters"]);
  }
}
function FilterGroupComponent_ul_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ul", 9);
    i0.ɵɵtemplate(1, FilterGroupComponent_ul_9_ng_container_1_Template, 3, 2, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.currentItem.filters)("ngForTrackBy", ctx_r2.trackByFunction);
  }
}
class FilterService {
  constructor() {
    this.normalizedValue = {
      logic: 'and',
      filters: []
    };
    this.filters = [];
  }
  addFilterGroup(item) {
    const filterGroup = {
      logic: 'and',
      filters: []
    };
    item.filters.push(filterGroup);
  }
  addFilterExpression(item) {
    const filterExpression = {
      operator: 'eq',
      value: null,
      field: null
    };
    item.filters.push(filterExpression);
  }
  remove(item, positionIndex, parentItem) {
    if (!parentItem) {
      parentItem = this.normalizedValue;
    }
    if (item === parentItem) {
      parentItem.filters = [];
      return;
    }
    const index = parentItem.filters.indexOf(item);
    if (index >= 0 && index === positionIndex) {
      parentItem.filters = parentItem.filters.filter(i => i !== item);
      return;
    }
    parentItem.filters.forEach(filter => filter.filters && this.remove(item, positionIndex, filter));
  }
}
FilterService.ɵfac = function FilterService_Factory(t) {
  return new (t || FilterService)();
};
FilterService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FilterService,
  factory: FilterService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterService, [{
    type: Injectable
  }], null, null);
})();

/**
 * @hidden
 */
const nullOperators = ["isnull", "isnotnull", "isempty", "isnotempty"];
/**
 * @hidden
 */
const numericOperators = [{
  text: "Is equal to",
  value: "eq"
}, {
  text: "Not equal to",
  value: "neq"
}, {
  text: "Greater than or equal to",
  value: "gte"
}, {
  text: "Greater than",
  value: "gt"
}, {
  text: "Less than or equal to",
  value: "lte"
}, {
  text: "Less than",
  value: "lt"
}, {
  text: "Is null",
  value: "isnull"
}, {
  text: "Is not null",
  value: "isnotnull"
}];
/**
 * @hidden
 */
const stringOperators = [{
  text: "Is equal to",
  value: "eq"
}, {
  text: "Not equal to",
  value: "neq"
}, {
  text: "Contains",
  value: "contains"
}, {
  text: "Does not contain",
  value: "doesnotcontain"
}, {
  text: "Starts with",
  value: "startswith"
}, {
  text: "Ends with",
  value: "endswith"
}, {
  text: "Is null",
  value: "isnull"
}, {
  text: "Is not null",
  value: "isnotnull"
}, {
  text: "Is empty",
  value: "isempty"
}, {
  text: "Is not empty",
  value: "isnotempty"
}];
/**
 * @hidden
 */
const booleanOperators = [{
  text: "Is equal to",
  value: "eq"
}, {
  text: "Is not equal to",
  value: "neq"
}];
/**
 * @hidden
 */
const dateOperators = [{
  text: "Is equal to",
  value: "eq"
}, {
  text: "Not equal to",
  value: "neq"
}, {
  text: "Greater than or equal to",
  value: "gte"
}, {
  text: "Greater than",
  value: "gt"
}, {
  text: "Less than or equal to",
  value: "lte"
}, {
  text: "Less than",
  value: "lt"
}, {
  text: "Is null",
  value: "isnull"
}, {
  text: "Is not null",
  value: "isnotnull"
}];
/**
 * @hidden
 */
const isArray = value => Array.isArray(value);
/**
 * @hidden
 */
const getKeyByValue = (object, value) => {
  return object && Object.keys(object).find(key => object[key] === value);
};
/**
 * @hidden
 */
const defaultStringOperators = {
  "filterEqOperator": "eq",
  "filterNotEqOperator": "neq",
  "filterContainsOperator": "contains",
  "filterNotContainsOperator": "doesnotcontain",
  "filterStartsWithOperator": "startswith",
  "filterEndsWithOperator": "endswith",
  "filterIsNullOperator": "isnull",
  "filterIsNotNullOperator": "isnotnull",
  "filterIsEmptyOperator": "isempty",
  "filterIsNotEmptyOperator": "isnotempty"
};
/**
 * @hidden
 */
const defaultNumericOperators = {
  "filterEqOperator": "eq",
  "filterNotEqOperator": "neq",
  "filterGteOperator": "gte",
  "filterGtOperator": "gt",
  "filterLteOperator": "lte",
  "filterLtOperator": "lt",
  "filterIsNullOperator": "isnull",
  "filterIsNotNullOperator": "isnotnull"
};
/**
 * @hidden
 */
const defaultDateOperators = {
  "filterEqOperator": "eq",
  "filterNotEqOperator": "neq",
  "filterAfterOrEqualOperator": "gte",
  "filterAfterOperator": "gt",
  "filterBeforeOrEqualOperator": "lte",
  "filterBeforeOperator": "lt",
  "filterIsNullOperator": "isnull",
  "filterIsNotNullOperator": "isnotnull"
};
/**
 * @hidden
 */
const defaultOperators = {
  "string": defaultStringOperators,
  "number": defaultNumericOperators,
  "date": defaultDateOperators
};
/**
 * @hidden
 */
const logicOperators = {
  "filterAndLogic": 'and',
  "filterOrLogic": 'or'
};
/**
 * @hidden
 */
const isFilterEditor = editorType => {
  const supportedEditorTypes = ['string', 'number', 'boolean', 'date'];
  return supportedEditorTypes.indexOf(editorType) >= 0;
};
/**
 * @hidden
 */
const localizeOperators = operators => localization => Object.keys(operators).map(key => ({
  text: localization.get(key),
  value: operators[key]
}));
/**
 * @hidden
 */
const isPresent = value => value !== null && value !== undefined;
/**
 * @hidden
 */
class FilterItem {}
FilterItem.ɵfac = function FilterItem_Factory(t) {
  return new (t || FilterItem)();
};
FilterItem.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FilterItem,
  factory: FilterItem.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterItem, [{
    type: Injectable
  }], null, null);
})();
/**
 * @hidden
 */
const selectors = {
  andButton: `button.k-group-start`,
  orButton: `button.k-group-end`,
  addFilterButton: `button[icon="filter-add-expression"]`,
  addGroupButton: `button[icon="filter-add-group"]`,
  removeButton: `button[icon="x"]`,
  filterFieldWrapper: `.k-filter-field`,
  filterOperatorWrapper: `.k-filter-operator`,
  filterValueEditorWrapper: `.k-filter-value`,
  kendoDropDownListComponent: `kendo-dropdownlist`,
  kendoInput: `.k-input`,
  kendoInputInner: `.k-input-inner`,
  inputElement: `input`,
  textAreaElement: `textarea`,
  kendoToolbar: `.k-toolbar`,
  kendoButton: `.k-button`,
  kendoFilterToolbarItem: `.k-toolbar-item`,
  kendoFilterToolbarButton: `.k-toolbar-button`
};

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-filter',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1718885150,
  version: '16.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
class FilterValueEditorTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
FilterValueEditorTemplateDirective.ɵfac = function FilterValueEditorTemplateDirective_Factory(t) {
  return new (t || FilterValueEditorTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
FilterValueEditorTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FilterValueEditorTemplateDirective,
  selectors: [["", "kendoFilterValueEditorTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterValueEditorTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoFilterValueEditorTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * Represents the [Kendo UI Filter Field component for Angular]({% slug api_filter_filterfieldcomponent %}).
 * The Filter Field component can be used to add Filter Expressions declaratively.
 * @example
 * ```ts
 *   @Component({
 *       selector: 'my-app',
 *       template: `
 *          <kendo-filter (valueChange)="onValueChange($event)">
 *              <kendo-filter-field field="country" editor="string" [operators]="['neq', 'eq', 'contains']"></kendo-filter-field>
 *              <kendo-filter-field field="budget" editor="number"></kendo-filter-field>
 *              <kendo-filter-field field="discontinued" title="Discontinued" editor="boolean"></kendo-filter-field>
 *              <kendo-filter-field field="ordered on" title="Ordered on" editor="date"></kendo-filter-field>
 *          <kendo-filter>
 *       `
 *   })
 *   export class AppComponent {
 *      onValueChange(e: CompositeFilterDescriptor){
 *          console.log(e)
 *      }
 *  }
 * ```
 */
class FilterFieldComponent {
  /**
   * Specifies the `title` text that will be displayed by the user-defined filter.
   * If the `title` isn't set, the value passed to `field` is used.
   */
  set title(_title) {
    if (_title) {
      this._title = _title;
    } else {
      this._title = this.field;
    }
  }
  get title() {
    return this._title;
  }
}
FilterFieldComponent.ɵfac = function FilterFieldComponent_Factory(t) {
  return new (t || FilterFieldComponent)();
};
FilterFieldComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterFieldComponent,
  selectors: [["kendo-filter-field"]],
  contentQueries: function FilterFieldComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, FilterValueEditorTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editorTemplate = _t.first);
    }
  },
  inputs: {
    field: "field",
    title: "title",
    editor: "editor",
    operators: "operators",
    editorFormat: "editorFormat"
  },
  decls: 0,
  vars: 0,
  template: function FilterFieldComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterFieldComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-filter-field',
      template: ``
    }]
  }], null, {
    field: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    editor: [{
      type: Input
    }],
    operators: [{
      type: Input
    }],
    editorFormat: [{
      type: Input
    }],
    editorTemplate: [{
      type: ContentChild,
      args: [FilterValueEditorTemplateDirective]
    }]
  });
})();

/**
 * @hidden
 */
class NavigationService {
  constructor(cdr, renderer) {
    this.cdr = cdr;
    this.renderer = renderer;
    this.hierarchicalFilterItems = [];
    this.flattenFilterItems = [];
    this.currentToolbarItemIndex = 0;
    this.currentToolbarItemChildrenIndex = 0;
    this.isInnerNavigationActivated = true;
    this.isFilterExpressionComponentFocused = false;
  }
  processKeyDown(key, event) {
    switch (key) {
      case Keys.ArrowUp:
        {
          event.preventDefault();
          if (!this.isFilterExpressionComponentFocused) {
            this.currentToolbarItemChildrenIndex = 0;
            this.currentToolbarItemIndex > 0 ? this.currentToolbarItemIndex-- : this.currentToolbarItemIndex;
            const elementToFocus = this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren[0];
            this.focusCurrentElement(elementToFocus);
          }
          break;
        }
      case Keys.ArrowDown:
        {
          event.preventDefault();
          if (!this.isFilterExpressionComponentFocused) {
            this.currentToolbarItemChildrenIndex = 0;
            this.currentToolbarItemIndex < this.flattenFilterItems.length - 1 ? this.currentToolbarItemIndex++ : this.currentToolbarItemIndex;
            const elementToFocus = this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren[0];
            this.focusCurrentElement(elementToFocus);
          }
          break;
        }
      case Keys.Enter:
        {
          const isEventTargetKendoFilterToolbarItem = event.target.closest(selectors.kendoFilterToolbarItem);
          const isEventTargetButton = event.target.closest(selectors.kendoButton);
          if (this.isInnerNavigationActivated && !this.isFilterExpressionComponentFocused && isEventTargetKendoFilterToolbarItem && !isEventTargetButton) {
            event.preventDefault();
            this.isFilterExpressionComponentFocused = true;
            const focusableElement = this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren[this.currentToolbarItemChildrenIndex];
            const elementToFocus = focusableElement.querySelector(selectors.KendoDropDownListComponent) || focusableElement.querySelector(selectors.kendoInputInner) || focusableElement.querySelector(selectors.inputElement) || focusableElement.querySelector(selectors.textAreaElement);
            this.focusCurrentElement(elementToFocus);
          }
          break;
        }
      case Keys.Escape:
        {
          if (this.isFilterExpressionComponentFocused) {
            event.preventDefault();
            this.isFilterExpressionComponentFocused = false;
            const elementToFocus = this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren[this.currentToolbarItemChildrenIndex];
            this.focusCurrentElement(elementToFocus);
          }
          break;
        }
      case Keys.ArrowRight:
        {
          if (this.isInnerNavigationActivated && !this.isFilterExpressionComponentFocused) {
            event.preventDefault();
            this.currentToolbarItemChildrenIndex < this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren.length - 1 ? this.currentToolbarItemChildrenIndex++ : this.currentToolbarItemChildrenIndex;
            const elementToFocus = this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren[this.currentToolbarItemChildrenIndex];
            this.focusCurrentElement(elementToFocus);
          }
          break;
        }
      case Keys.ArrowLeft:
        {
          if (this.isInnerNavigationActivated && !this.isFilterExpressionComponentFocused) {
            event.preventDefault();
            this.currentToolbarItemChildrenIndex > 0 ? this.currentToolbarItemChildrenIndex-- : this.currentToolbarItemChildrenIndex;
            const elementToFocus = this.flattenFilterItems[this.currentToolbarItemIndex].focusableChildren[this.currentToolbarItemChildrenIndex];
            this.focusCurrentElement(elementToFocus);
          }
          break;
        }
      default:
        break;
    }
  }
  focusCurrentElement(element, isOnMouseDown) {
    this.renderer.setAttribute(this.currentlyFocusedElement, 'tabindex', '-1');
    this.currentlyFocusedElement = element;
    if (element) {
      this.renderer.setAttribute(this.currentlyFocusedElement, 'tabindex', '0');
      if (!isOnMouseDown) {
        this.currentlyFocusedElement.focus();
      }
    }
  }
  flattenHierarchicalFilterItems(filterItems) {
    filterItems.forEach(filterRow => {
      const flattenItem = {
        component: filterRow,
        isGroup: false,
        toolbarElement: filterRow.toolbarElement,
        focusableChildren: []
      };
      this.flattenFilterItems.push(flattenItem);
      if (filterRow['operators'] && filterRow['filterItems']?.length > 0) {
        this.setGroupItemChildren(flattenItem, filterRow);
        this.flattenHierarchicalFilterItems(filterRow['filterItems']);
      } else if (filterRow['operators'] && filterRow['filterItems']?.length === 0) {
        this.setGroupItemChildren(flattenItem, filterRow);
      } else {
        flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.filterFieldWrapper));
        if (filterRow.toolbarElement.querySelector('.k-filter-operator')) {
          flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.filterOperatorWrapper));
        }
        flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.filterValueEditorWrapper));
        flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.removeButton));
      }
    });
  }
  setGroupItemChildren(flattenItem, filterRow) {
    flattenItem.isGroup = true;
    flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.andButton));
    flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.orButton));
    flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.addFilterButton));
    flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.addGroupButton));
    flattenItem.focusableChildren.push(filterRow.toolbarElement.querySelector(selectors.removeButton));
  }
  setItemIndexes() {
    this.flattenFilterItems.forEach((item, index) => {
      item.component['itemNumber'] = index;
    });
    this.cdr.detectChanges();
  }
  reset(items) {
    this.flattenFilterItems = [];
    this.hierarchicalFilterItems = items;
    this.flattenHierarchicalFilterItems(items);
    this.setItemIndexes();
  }
}
NavigationService.ɵfac = function NavigationService_Factory(t) {
  return new (t || NavigationService)(i0.ɵɵinject(i0.ChangeDetectorRef), i0.ɵɵinject(i0.Renderer2));
};
NavigationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NavigationService,
  factory: NavigationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();

/**
 * @hidden
 */
const FilterErrorMessages = {
  missingFilters: `Pass at least one user-defined filter through the [filters] input property or nest kendo-filter-field components. See http://www.telerik.com/kendo-angular-ui/components/filter/#data-binding`,
  missingFilterForUsedField: field => `There is no user-defined filter with '${field}' field provided through the [filters] input property.`,
  missingValueForBooleanField: field => `Provide a value for the boolean '${field}' user-defined filter as the operator is always set to 'eq'.`,
  operatorBooleanField: field => `The operator of the boolean '${field}' user-defined filter is always set to 'eq'.`,
  filterMissingUsedOperator: (field, operator) => `The user-defined filter with field '${field}' is missing the '${operator}' operator.`,
  improperNumericEditorValue: title => `The provided value for the numeric editor of the '${title}' filter expression isn't one of a supported type string or NumberFormat. See http://www.telerik.com/kendo-angular-ui/components/filter/#editor-formats/`,
  improperDateEditorValue: title => `The provided value for the date editor of the '${title}' filter expression isn't one of a supported type string or DateFormat. See http://www.telerik.com/kendo-angular-ui/components/filter/#editor-formats/`
};

/**
 * @hidden
 */
class BaseFilterRowComponent {
  constructor(element, navigationService, localization, renderer) {
    this.element = element;
    this.navigationService = navigationService;
    this.localization = localization;
    this.renderer = renderer;
    this.valueChange = new EventEmitter();
    this.itemNumber = 0;
  }
  get toolbarElement() {
    return this.element.nativeElement.querySelector('.k-toolbar');
  }
  messageFor(key) {
    return this.localization.get(key);
  }
  onMouseDown(event) {
    let elementToFocus;
    const closestFilterToolbarItem = event.target.closest(selectors.kendoFilterToolbarItem);
    const closestButton = event.target.closest(selectors.kendoFilterToolbarButton);
    const closestToolbarItem = event.target.closest(selectors.kendoToolbar);
    if (closestFilterToolbarItem || closestButton) {
      const index = Array.from(closestToolbarItem.children).indexOf(closestFilterToolbarItem) > -1 ? Array.from(closestToolbarItem.children).indexOf(closestFilterToolbarItem) : Array.from(closestToolbarItem.children).indexOf(closestButton);
      this.navigationService.currentToolbarItemChildrenIndex = index;
      this.navigationService.isInnerNavigationActivated = true;
      this.navigationService.isFilterExpressionComponentFocused = true;
      const wrapperElement = this.navigationService.flattenFilterItems[this.itemNumber].focusableChildren[index];
      elementToFocus = wrapperElement.querySelector(selectors.kendoDropDownListComponent) || wrapperElement.querySelector(selectors.kendoInput) || wrapperElement.querySelector(selectors.kendoInputInner) || wrapperElement.querySelector(selectors.inputElement) || wrapperElement.querySelector(selectors.textAreaElement) || wrapperElement.querySelector(selectors.kendoButton) || wrapperElement;
    } else {
      this.navigationService.currentToolbarItemChildrenIndex = 0;
      this.navigationService.isInnerNavigationActivated = false;
      this.navigationService.isFilterExpressionComponentFocused = false;
      elementToFocus = this.navigationService.flattenFilterItems[this.itemNumber].focusableChildren[0];
    }
    this.navigationService.currentToolbarItemIndex = this.itemNumber;
    this.navigationService.focusCurrentElement(elementToFocus, true);
  }
}
BaseFilterRowComponent.ɵfac = function BaseFilterRowComponent_Factory(t) {
  return new (t || BaseFilterRowComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.Renderer2));
};
BaseFilterRowComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseFilterRowComponent,
  inputs: {
    index: "index"
  },
  outputs: {
    valueChange: "valueChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseFilterRowComponent, [{
    type: Directive,
    args: [{}]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: NavigationService
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.Renderer2
    }];
  }, {
    index: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();

/**
 * @hidden
 */
class AriaLabelValueDirective {
  constructor(hostElement, renderer) {
    this.hostElement = hostElement;
    this.renderer = renderer;
  }
  ngOnInit() {
    const target = this.hostElement.nativeElement.querySelector('input') || this.hostElement.nativeElement;
    this.renderer.setAttribute(target, 'aria-label', this.ariaLabel);
  }
}
AriaLabelValueDirective.ɵfac = function AriaLabelValueDirective_Factory(t) {
  return new (t || AriaLabelValueDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
AriaLabelValueDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AriaLabelValueDirective,
  selectors: [["", "kendoAriaLabelValue", ""]],
  inputs: {
    ariaLabel: [0, "kendoAriaLabelValue", "ariaLabel"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AriaLabelValueDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoAriaLabelValue]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    ariaLabel: [{
      type: Input,
      args: ['kendoAriaLabelValue']
    }]
  });
})();

/**
 * @hidden
 */
class FilterNumericEditorComponent {
  constructor(localization) {
    this.localization = localization;
    this.valueChange = new EventEmitter();
  }
  messageFor(key) {
    return this.localization.get(key);
  }
  onValueChange(value) {
    this.currentItem.value = value;
    this.valueChange.emit();
  }
}
FilterNumericEditorComponent.ɵfac = function FilterNumericEditorComponent_Factory(t) {
  return new (t || FilterNumericEditorComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
FilterNumericEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterNumericEditorComponent,
  selectors: [["kendo-filter-numeric-editor"]],
  inputs: {
    currentItem: "currentItem",
    isDisabled: "isDisabled",
    format: "format"
  },
  outputs: {
    valueChange: "valueChange"
  },
  decls: 2,
  vars: 7,
  consts: [[3, "valueChange", "tabindex", "kendoAriaLabelValue", "value", "disabled", "format"], [3, "increment", "decrement"]],
  template: function FilterNumericEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-numerictextbox", 0);
      i0.ɵɵlistener("valueChange", function FilterNumericEditorComponent_Template_kendo_numerictextbox_valueChange_0_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵelement(1, "kendo-numerictextbox-messages", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("tabindex", -1)("kendoAriaLabelValue", ctx.messageFor("filterValueAriaLabel"))("value", ctx.currentItem.value)("disabled", ctx.isDisabled)("format", ctx.format);
      i0.ɵɵadvance();
      i0.ɵɵproperty("increment", ctx.messageFor("editorNumericIncrement"))("decrement", ctx.messageFor("editorNumericDecrement"));
    }
  },
  dependencies: [i2.NumericTextBoxComponent, i2.NumericTextBoxCustomMessagesComponent, AriaLabelValueDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterNumericEditorComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-filter-numeric-editor',
      template: `
        <kendo-numerictextbox
            [tabindex]="-1"
            [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
            [value]="currentItem.value"
            (valueChange)="onValueChange($event)"
            [disabled]="isDisabled"
            [format]="format">
            <kendo-numerictextbox-messages
                [increment]="messageFor('editorNumericIncrement')"
                [decrement]="messageFor('editorNumericDecrement')">
            </kendo-numerictextbox-messages>
        </kendo-numerictextbox>
  `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    currentItem: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();

/**
 * @hidden
 */
class FilterTextEditorComponent {
  constructor(localization) {
    this.localization = localization;
    this.valueChange = new EventEmitter();
  }
  messageFor(key) {
    return this.localization.get(key);
  }
  onValueChange(value) {
    this.currentItem.value = value;
    this.valueChange.emit();
  }
}
FilterTextEditorComponent.ɵfac = function FilterTextEditorComponent_Factory(t) {
  return new (t || FilterTextEditorComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
FilterTextEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterTextEditorComponent,
  selectors: [["kendo-filter-text-editor"]],
  inputs: {
    currentItem: "currentItem",
    isDisabled: "isDisabled"
  },
  outputs: {
    valueChange: "valueChange"
  },
  decls: 1,
  vars: 4,
  consts: [[3, "valueChange", "tabindex", "kendoAriaLabelValue", "value", "disabled"]],
  template: function FilterTextEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-textbox", 0);
      i0.ɵɵlistener("valueChange", function FilterTextEditorComponent_Template_kendo_textbox_valueChange_0_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("tabindex", -1)("kendoAriaLabelValue", ctx.messageFor("filterValueAriaLabel"))("value", ctx.currentItem.value)("disabled", ctx.isDisabled);
    }
  },
  dependencies: [i2.TextBoxComponent, AriaLabelValueDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterTextEditorComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-filter-text-editor',
      template: `
        <kendo-textbox
            [tabindex]="-1"
            [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
            [value]="currentItem.value"
            (valueChange)="onValueChange($event)"
            [disabled]="isDisabled">
        </kendo-textbox>
  `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    currentItem: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();

/**
 * @hidden
 */
class FilterExpressionOperatorsComponent {
  constructor(localization) {
    this.localization = localization;
    this.valueChange = new EventEmitter();
    this.operators = [];
  }
  messageFor(key) {
    return this.localization.get(key);
  }
  getOperator(operatorValue) {
    return this.messageFor(getKeyByValue(defaultOperators[this.editorType], operatorValue));
  }
  operatorValueChange(value) {
    this.valueChange.emit(value);
  }
}
FilterExpressionOperatorsComponent.ɵfac = function FilterExpressionOperatorsComponent_Factory(t) {
  return new (t || FilterExpressionOperatorsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
FilterExpressionOperatorsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterExpressionOperatorsComponent,
  selectors: [["kendo-filter-expression-operators"]],
  inputs: {
    currentItem: "currentItem",
    editorType: "editorType",
    operators: "operators"
  },
  outputs: {
    valueChange: "valueChange"
  },
  decls: 3,
  vars: 6,
  consts: [["textField", "text", "valueField", "value", 3, "valueChange", "tabindex", "kendoAriaLabelValue", "data", "title", "value", "valuePrimitive"], ["kendoDropDownListValueTemplate", ""], ["kendoDropDownListItemTemplate", ""]],
  template: function FilterExpressionOperatorsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dropdownlist", 0);
      i0.ɵɵtwoWayListener("valueChange", function FilterExpressionOperatorsComponent_Template_kendo_dropdownlist_valueChange_0_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.currentItem.operator, $event) || (ctx.currentItem.operator = $event);
        return $event;
      });
      i0.ɵɵlistener("valueChange", function FilterExpressionOperatorsComponent_Template_kendo_dropdownlist_valueChange_0_listener($event) {
        return ctx.operatorValueChange($event);
      });
      i0.ɵɵtemplate(1, FilterExpressionOperatorsComponent_ng_template_1_Template, 2, 1, "ng-template", 1)(2, FilterExpressionOperatorsComponent_ng_template_2_Template, 2, 1, "ng-template", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("tabindex", -1)("kendoAriaLabelValue", ctx.messageFor("filterOperatorAriaLabel"))("data", ctx.operators)("title", ctx.messageFor("filterExpressionOperators"));
      i0.ɵɵtwoWayProperty("value", ctx.currentItem.operator);
      i0.ɵɵproperty("valuePrimitive", true);
    }
  },
  dependencies: [i2$1.ItemTemplateDirective, i2$1.ValueTemplateDirective, i2$1.DropDownListComponent, AriaLabelValueDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterExpressionOperatorsComponent, [{
    type: Component,
    args: [{
      selector: "kendo-filter-expression-operators",
      template: `
        <kendo-dropdownlist
            [tabindex]="-1"
            [kendoAriaLabelValue]="messageFor('filterOperatorAriaLabel')"
            [data]="operators"
            [title]="messageFor('filterExpressionOperators')"
            [(value)]="currentItem.operator"
            (valueChange)="operatorValueChange($event)"
            [valuePrimitive]="true"
            textField="text"
            valueField="value"
        >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span>{{ getOperator(dataItem.value) }}</span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span>{{ getOperator(dataItem.value) }}</span>
            </ng-template>
        </kendo-dropdownlist>
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    currentItem: [{
      type: Input
    }],
    editorType: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    operators: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class FilterBooleanEditorComponent {
  constructor(localization, cdr) {
    this.localization = localization;
    this.cdr = cdr;
    this.valueChange = new EventEmitter();
    this.items = this.getValueItems();
    this.defaultItem = this.getDefaultItem();
  }
  ngOnInit() {
    this.localizationSubscription = this.localization.changes.subscribe(() => {
      this.defaultItem = this.getDefaultItem();
      this.items = this.getValueItems();
      this.cdr.detectChanges();
    });
  }
  getDefaultItem() {
    return {
      text: this.localization.get("filterBooleanAll"),
      value: null
    };
  }
  getValueItems() {
    return [{
      text: this.localization.get("filterIsTrue"),
      value: true
    }, {
      text: this.localization.get("filterIsFalse"),
      value: false
    }];
  }
  ngOnDestroy() {
    if (this.localizationSubscription) {
      this.localizationSubscription.unsubscribe();
    }
  }
  messageFor(key) {
    return this.localization.get(key);
  }
  onValueChange(value) {
    this.currentItem.value = value;
    this.valueChange.emit();
  }
}
FilterBooleanEditorComponent.ɵfac = function FilterBooleanEditorComponent_Factory(t) {
  return new (t || FilterBooleanEditorComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
FilterBooleanEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterBooleanEditorComponent,
  selectors: [["kendo-filter-boolean-editor"]],
  inputs: {
    currentItem: "currentItem"
  },
  outputs: {
    valueChange: "valueChange"
  },
  decls: 1,
  vars: 6,
  consts: [["textField", "text", "valueField", "value", 3, "valueChange", "tabindex", "kendoAriaLabelValue", "value", "data", "defaultItem", "valuePrimitive"]],
  template: function FilterBooleanEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dropdownlist", 0);
      i0.ɵɵlistener("valueChange", function FilterBooleanEditorComponent_Template_kendo_dropdownlist_valueChange_0_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("tabindex", -1)("kendoAriaLabelValue", ctx.messageFor("filterValueAriaLabel"))("value", ctx.currentItem.value)("data", ctx.items)("defaultItem", ctx.defaultItem)("valuePrimitive", true);
    }
  },
  dependencies: [i2$1.DropDownListComponent, AriaLabelValueDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterBooleanEditorComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-filter-boolean-editor',
      template: `
    <kendo-dropdownlist
        [tabindex]="-1"
        [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
        [value]="currentItem.value"
        (valueChange)="onValueChange($event)"
        [data]="items"
        [defaultItem]="defaultItem"
        [valuePrimitive]="true"
        textField="text"
        valueField="value"
        >
    </kendo-dropdownlist>
  `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    currentItem: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();

/**
 * @hidden
 */
class FilterDateEditorComponent {
  constructor(localization) {
    this.localization = localization;
    this.valueChange = new EventEmitter();
  }
  messageFor(key) {
    return this.localization.get(key);
  }
  onValueChange(value) {
    this.currentItem.value = value;
    this.valueChange.emit();
  }
}
FilterDateEditorComponent.ɵfac = function FilterDateEditorComponent_Factory(t) {
  return new (t || FilterDateEditorComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
FilterDateEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterDateEditorComponent,
  selectors: [["kendo-filter-date-editor"]],
  inputs: {
    currentItem: "currentItem",
    isDisabled: "isDisabled",
    format: "format"
  },
  outputs: {
    valueChange: "valueChange"
  },
  decls: 2,
  vars: 7,
  consts: [[3, "valueChange", "tabindex", "kendoAriaLabelValue", "value", "disabled", "format"], [3, "toggle", "today"]],
  template: function FilterDateEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-datepicker", 0);
      i0.ɵɵlistener("valueChange", function FilterDateEditorComponent_Template_kendo_datepicker_valueChange_0_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵelement(1, "kendo-datepicker-messages", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("tabindex", -1)("kendoAriaLabelValue", ctx.messageFor("filterValueAriaLabel"))("value", ctx.currentItem.value)("disabled", ctx.isDisabled)("format", ctx.format);
      i0.ɵɵadvance();
      i0.ɵɵproperty("toggle", ctx.messageFor("editorDateToggleText"))("today", ctx.messageFor("editorDateTodayText"));
    }
  },
  dependencies: [i2$2.DatePickerComponent, i2$2.DatePickerCustomMessagesComponent, AriaLabelValueDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterDateEditorComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-filter-date-editor',
      template: `
        <kendo-datepicker
            [tabindex]="-1"
            [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
            [value]="currentItem.value"
            (valueChange)="onValueChange($event)"
            [disabled]="isDisabled"
            [format]="format">
            <kendo-datepicker-messages
                [toggle]="messageFor('editorDateToggleText')"
                [today]="messageFor('editorDateTodayText')">
            </kendo-datepicker-messages>
        </kendo-datepicker>
  `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    currentItem: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();

/**
 * @hidden
 */
class FilterExpressionComponent extends BaseFilterRowComponent {
  constructor(filterService, cdr, element, navigationService, localization, renderer) {
    super(element, navigationService, localization, renderer);
    this.filterService = filterService;
    this.cdr = cdr;
    /**
     * @hidden
     */
    this.xIcon = xIcon;
    this.operators = [];
    this.isBoolean = false;
    this.isEditorDisabled = false;
  }
  get currentFilterExpression() {
    return this.getFilterExpressionByField(this.currentItem.field);
  }
  get numericEditorFormat() {
    const isSupportedFormat = typeof this.editorFormat !== 'string' && !this.isNumberFormat(this.editorFormat);
    if (this.editorFormat && isSupportedFormat) {
      console.warn(FilterErrorMessages.improperNumericEditorValue(this.currentFilterExpression.title));
    }
    return this.editorFormat;
  }
  get dateEditorFormat() {
    const isSupportedFormat = typeof this.editorFormat !== 'string' && !this.isDateFormat(this.editorFormat);
    if (this.editorFormat && isSupportedFormat) {
      console.warn(FilterErrorMessages.improperDateEditorValue(this.currentFilterExpression.title));
    }
    return this.editorFormat;
  }
  isNumberFormat(obj) {
    if (isDevMode() && obj && (obj['currency'] || obj['currencyDisplay'] || obj['maximumFractionDigits'] || obj['minimumIntegerDigits'] || obj['style'] || obj['useGrouping'])) {
      return true;
    } else {
      return false;
    }
  }
  isDateFormat(obj) {
    if (isDevMode() && obj && obj['displayFormat'] && obj['inputFormat']) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit() {
    this.isEditorDisabled = nullOperators.indexOf(this.currentItem.operator) >= 0;
    const foundFilter = this.getFilterExpressionByField(this.currentItem.field);
    if (this.currentItem.field) {
      this.setOperators(foundFilter);
    }
    if (foundFilter?.editorFormat) {
      this.editorFormat = foundFilter.editorFormat;
    }
    const defaultFilter = this.getFilterExpressionByField(this.filterService.filters[0].field);
    if (!this.currentItem.field) {
      this.currentItem.field = this.filterService.filters[0].field;
      if (defaultFilter.editorFormat) {
        this.editorFormat = defaultFilter.editorFormat;
      }
      this.setOperators(defaultFilter);
    }
    this.setEditorTemplate();
    this.localizationSubscription = this.localization.changes.subscribe(() => {
      this.setOperators(foundFilter || defaultFilter);
      this.cdr.detectChanges();
    });
  }
  ngOnDestroy() {
    if (this.localizationSubscription) {
      this.localizationSubscription.unsubscribe();
    }
  }
  normalizeOperators(filterEditor, operators) {
    const result = [];
    for (let j = 0; j < operators.length; j++) {
      if (isFilterEditor(filterEditor)) {
        result.push({
          value: operators[j],
          text: this.localization.get(getKeyByValue(defaultOperators[filterEditor], operators[j]))
        });
      }
    }
    return result;
  }
  getFilterExpressionByField(name) {
    const foundFilter = this.filterService.filters.find(filter => filter.field === name);
    if (foundFilter) {
      return foundFilter;
    }
    return null;
  }
  filterValueChange(value) {
    this.navigationService.currentToolbarItemIndex = this.itemNumber;
    this.navigationService.currentToolbarItemChildrenIndex = 0;
    this.currentItem.value = null;
    this.currentItem.field = value;
    this.setEditorTemplate();
    const foundFilter = this.getFilterExpressionByField(this.currentItem.field);
    this.setOperators(foundFilter);
    this.editorFormat = foundFilter.editorFormat;
    this.valueChange.emit();
  }
  getDefaultOperators(operatorsType) {
    switch (operatorsType) {
      case 'string':
        return localizeOperators(defaultStringOperators)(this.localization);
      case 'number':
        return localizeOperators(defaultNumericOperators)(this.localization);
      case 'date':
        return localizeOperators(defaultDateOperators)(this.localization);
      default:
        break;
    }
  }
  getEditorType() {
    const filterExpression = this.filterService.filters.find(filterExpression => filterExpression.field === this.currentItem.field);
    return filterExpression?.editor;
  }
  getFilters() {
    return this.filterService.filters || [];
  }
  removeFilterExpression() {
    this.filterService.remove(this.currentItem, this.index);
    this.valueChange.emit(true);
  }
  setOperators(filter) {
    this.isBoolean = filter.editor === 'boolean';
    if (this.isBoolean) {
      return;
    }
    if (filter.operators) {
      const localizedOperators = this.normalizeOperators(filter.editor, filter.operators);
      this.operators = localizedOperators;
    } else {
      this.operators = this.getDefaultOperators(filter.editor);
    }
    if (!this.currentItem.operator) {
      this.currentItem.operator = this.operators[0].value;
    }
    const operatorDoesNotExist = !this.operators.find(operator => operator.value === this.currentItem.operator);
    if (this.currentItem.operator && operatorDoesNotExist) {
      this.currentItem.operator = this.operators[0].value;
    }
  }
  onOperatorChange(value) {
    this.navigationService.currentToolbarItemIndex = this.itemNumber;
    this.navigationService.currentToolbarItemChildrenIndex = 1;
    this.valueChange.emit();
    if (nullOperators.includes(value)) {
      this.currentItem.value = null;
      this.isEditorDisabled = true;
    } else {
      this.isEditorDisabled = false;
    }
  }
  setEditorTemplate() {
    const filterExpression = this.filterService.filters.find(filter => filter.field === this.currentItem.field);
    this.editorTemplate = filterExpression?.editorTemplate;
  }
}
FilterExpressionComponent.ɵfac = function FilterExpressionComponent_Factory(t) {
  return new (t || FilterExpressionComponent)(i0.ɵɵdirectiveInject(FilterService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.Renderer2));
};
FilterExpressionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterExpressionComponent,
  selectors: [["kendo-filter-expression"]],
  inputs: {
    currentItem: "currentItem"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: FilterItem,
    useExisting: forwardRef(() => FilterExpressionComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 9,
  vars: 12,
  consts: [[1, "k-filter-toolbar"], ["role", "toolbar", 1, "k-toolbar", "k-toolbar-md", 3, "mousedown"], [1, "k-filter-field", "k-toolbar-item"], ["textField", "title", "valueField", "field", 3, "valueChange", "tabindex", "kendoAriaLabelValue", "title", "data", "value", "valuePrimitive"], ["class", "k-filter-operator k-toolbar-item", 4, "ngIf"], [1, "k-filter-value", "k-toolbar-item"], [3, "ngSwitch", 4, "ngIf"], [4, "ngIf"], ["kendoButton", "", "tabindex", "-1", "icon", "x", "fillMode", "flat", 1, "k-toolbar-button", 3, "click", "svgIcon", "title"], [1, "k-filter-operator", "k-toolbar-item"], [3, "valueChange", "currentItem", "operators", "editorType"], [3, "ngSwitch"], [3, "currentItem", "isDisabled", "valueChange", 4, "ngSwitchCase"], [3, "currentItem", "isDisabled", "format", "valueChange", 4, "ngSwitchCase"], [3, "currentItem", "valueChange", 4, "ngSwitchCase"], [3, "valueChange", "currentItem", "isDisabled"], [3, "valueChange", "currentItem", "isDisabled", "format"], [3, "valueChange", "currentItem"], [3, "templateContext"]],
  template: function FilterExpressionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
      i0.ɵɵlistener("mousedown", function FilterExpressionComponent_Template_div_mousedown_1_listener($event) {
        return ctx.onMouseDown($event);
      });
      i0.ɵɵelementStart(2, "div", 2)(3, "kendo-dropdownlist", 3);
      i0.ɵɵlistener("valueChange", function FilterExpressionComponent_Template_kendo_dropdownlist_valueChange_3_listener($event) {
        return ctx.filterValueChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(4, FilterExpressionComponent_div_4_Template, 2, 3, "div", 4);
      i0.ɵɵelementStart(5, "div", 5);
      i0.ɵɵtemplate(6, FilterExpressionComponent_ng_container_6_Template, 5, 5, "ng-container", 6)(7, FilterExpressionComponent_ng_container_7_Template, 2, 4, "ng-container", 7);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "button", 8);
      i0.ɵɵlistener("click", function FilterExpressionComponent_Template_button_click_8_listener() {
        return ctx.removeFilterExpression();
      });
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵattribute("aria-label", ctx.messageFor("filterToolbarAriaLabel"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("tabindex", -1)("kendoAriaLabelValue", ctx.messageFor("filterFieldAriaLabel"))("title", ctx.messageFor("filterExpressionFilters"))("data", ctx.getFilters())("value", ctx.currentItem.field)("valuePrimitive", true);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isBoolean);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.editorTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.editorTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("svgIcon", ctx.xIcon)("title", ctx.messageFor("remove"));
    }
  },
  dependencies: [i4.NgIf, i4.NgSwitch, i4.NgSwitchCase, i2$1.DropDownListComponent, i6.ButtonComponent, i6.TemplateContextDirective, FilterNumericEditorComponent, FilterTextEditorComponent, FilterExpressionOperatorsComponent, FilterBooleanEditorComponent, FilterDateEditorComponent, AriaLabelValueDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterExpressionComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: FilterItem,
        useExisting: forwardRef(() => FilterExpressionComponent)
      }],
      selector: 'kendo-filter-expression',
      template: `
        <div class="k-filter-toolbar">
            <div class="k-toolbar k-toolbar-md" role="toolbar" [attr.aria-label]="messageFor('filterToolbarAriaLabel')" (mousedown)="onMouseDown($event)">
                <div class="k-filter-field k-toolbar-item" >
                    <kendo-dropdownlist
                        [tabindex]="-1"
                        [kendoAriaLabelValue]="messageFor('filterFieldAriaLabel')"
                        [title]="messageFor('filterExpressionFilters')"
                        [data]="getFilters()"
                        textField="title"
                        valueField="field"
                        [value]="currentItem.field"
                        [valuePrimitive]="true"
                        (valueChange)="filterValueChange($event)">
                    </kendo-dropdownlist>
                </div>
                <div *ngIf="!isBoolean" class="k-filter-operator k-toolbar-item" >
                    <kendo-filter-expression-operators
                        [currentItem]="currentItem"
                        [operators]="operators"
                        [editorType]="getEditorType()"
                        (valueChange)="onOperatorChange($event);">
                    </kendo-filter-expression-operators>
                </div>

                <div class="k-filter-value k-toolbar-item">
                    <ng-container *ngIf="!editorTemplate" [ngSwitch]="getEditorType()">
                        <kendo-filter-text-editor *ngSwitchCase="'string'" [currentItem]="currentItem" [isDisabled]="isEditorDisabled" (valueChange)="valueChange.emit()"></kendo-filter-text-editor>
                        <kendo-filter-numeric-editor *ngSwitchCase="'number'" [currentItem]="currentItem" [isDisabled]="isEditorDisabled" [format]="numericEditorFormat" (valueChange)="valueChange.emit()"></kendo-filter-numeric-editor>
                        <kendo-filter-boolean-editor *ngSwitchCase="'boolean'" [currentItem]="currentItem" (valueChange)="valueChange.emit()"></kendo-filter-boolean-editor>
                        <kendo-filter-date-editor *ngSwitchCase="'date'" [currentItem]="currentItem" [isDisabled]="isEditorDisabled" [format]="dateEditorFormat" (valueChange)="valueChange.emit()"></kendo-filter-date-editor>
                    </ng-container>
                    <ng-container *ngIf="editorTemplate">
                        <ng-template
                            [templateContext]="{templateRef: editorTemplate, $implicit: currentItem}">
                        </ng-template>
                    </ng-container>
                </div>

                <button
                    kendoButton
                    class="k-toolbar-button"
                    tabindex="-1"
                    icon="x"
                    [svgIcon]="xIcon"
                    fillMode="flat"
                    [title]="messageFor('remove')"
                    (click)="removeFilterExpression()">
                </button>
            </div>
        </div>
  `
    }]
  }], function () {
    return [{
      type: FilterService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: NavigationService
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.Renderer2
    }];
  }, {
    currentItem: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class FilterGroupComponent extends BaseFilterRowComponent {
  constructor(filterService, cdr, element, navigationService, localization, renderer) {
    super(element, navigationService, localization, renderer);
    this.filterService = filterService;
    this.cdr = cdr;
    /**
     * @hidden
     */
    this.xIcon = xIcon;
    /**
     * @hidden
     */
    this.filterAddGroupIcon = filterAddGroupIcon;
    /**
     * @hidden
     */
    this.filterAddExpressionIcon = filterAddExpressionIcon;
    this.currentItem = {
      logic: 'or',
      filters: []
    };
    /**
     * @hidden
     */
    this.trackByFunction = index => {
      return index;
    };
    this.operators = [];
  }
  get filterItems() {
    return this._filterItems.toArray();
  }
  /**
   * @hidden
   */
  handleExpressionValueChange(isRemoveOperation) {
    if (isRemoveOperation) {
      // due to tracking by index, the filters should first be set to [] so that all rerender afterwards
      const filtersCopy = this.currentItem.filters;
      this.currentItem.filters = [];
      this.cdr.detectChanges();
      this.currentItem.filters = filtersCopy;
    }
    this.valueChange.emit(isRemoveOperation);
  }
  ngOnInit() {
    this.operators = this.getLogicOperators();
    this.localizationSubscription = this.localization.changes.subscribe(() => {
      this.operators = this.getLogicOperators();
      this.cdr.detectChanges();
    });
  }
  ngOnDestroy() {
    if (this.localizationSubscription) {
      this.localizationSubscription.unsubscribe();
    }
  }
  getLogicOperators() {
    return localizeOperators(logicOperators)(this.localization);
  }
  getOperator(operatorValue) {
    return this.messageFor(getKeyByValue(logicOperators, operatorValue));
  }
  selectedChange(logicOperator) {
    if (this.currentItem.logic !== logicOperator) {
      this.currentItem.logic = logicOperator;
      this.valueChange.emit();
    }
  }
  addFilterExpression() {
    this.filterService.addFilterExpression(this.currentItem);
    this.valueChange.emit();
  }
  addFilterGroup() {
    this.filterService.addFilterGroup(this.currentItem);
    this.valueChange.emit();
  }
  removeFilterGroup() {
    this.filterService.remove(this.currentItem, this.index);
    this.cdr.detectChanges();
    this.valueChange.emit(true);
  }
  onMouseDown(event) {
    const toolbarItemElement = Array.from(event.target.closest(selectors.kendoToolbar).children).indexOf(event.target.closest(selectors.kendoFilterToolbarItem));
    const buttonElement = Array.from(event.target.closest(selectors.kendoToolbar).children).indexOf(event.target.closest(selectors.kendoFilterToolbarButton));
    let index = (toolbarItemElement > -1 ? toolbarItemElement : buttonElement) + 1;
    if (event.target.closest(selectors.andButton)) {
      index = 0;
    }
    if (event.target.closest(selectors.orButton)) {
      index = 1;
    }
    this.navigationService.currentToolbarItemChildrenIndex = index;
    this.navigationService.isInnerNavigationActivated = true;
    const elementToFocus = this.navigationService.flattenFilterItems[this.itemNumber].focusableChildren[index];
    this.navigationService.isFilterExpressionComponentFocused = false;
    this.navigationService.currentToolbarItemIndex = this.itemNumber;
    this.navigationService.focusCurrentElement(elementToFocus, true);
  }
}
FilterGroupComponent.ɵfac = function FilterGroupComponent_Factory(t) {
  return new (t || FilterGroupComponent)(i0.ɵɵdirectiveInject(FilterService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.Renderer2));
};
FilterGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterGroupComponent,
  selectors: [["kendo-filter-group"]],
  viewQuery: function FilterGroupComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(FilterItem, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._filterItems = _t);
    }
  },
  inputs: {
    currentItem: "currentItem"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: FilterItem,
    useExisting: forwardRef(() => FilterGroupComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 10,
  vars: 11,
  consts: [[1, "k-filter-toolbar"], ["role", "toolbar", 1, "k-toolbar", "k-toolbar-md", 3, "mousedown"], ["role", "group", 1, "k-toolbar-button-group", "k-button-group", "k-button-group-solid"], ["tabindex", "-1", "kendoButton", "", "class", "k-toolbar-button", 3, "ngClass", "selected", "title", "click", 4, "ngFor", "ngForOf"], ["kendoButton", "", "tabindex", "-1", "icon", "filter-add-expression", 1, "k-toolbar-button", 3, "click", "title", "svgIcon"], ["kendoButton", "", "tabindex", "-1", "icon", "filter-add-group", 1, "k-toolbar-button", 3, "click", "title", "svgIcon"], ["kendoButton", "", "tabindex", "-1", "icon", "x", "fillMode", "flat", 1, "k-toolbar-button", 3, "click", "svgIcon", "title"], ["class", "k-filter-lines", "role", "group", 4, "ngIf"], ["tabindex", "-1", "kendoButton", "", 1, "k-toolbar-button", 3, "click", "ngClass", "selected", "title"], ["role", "group", 1, "k-filter-lines"], [4, "ngFor", "ngForOf", "ngForTrackBy"], ["class", "k-filter-item", "role", "treeitem", 4, "ngIf"], ["role", "treeitem", 1, "k-filter-item"], [3, "valueChange", "currentItem", "index"]],
  template: function FilterGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
      i0.ɵɵlistener("mousedown", function FilterGroupComponent_Template_div_mousedown_1_listener($event) {
        return ctx.onMouseDown($event);
      });
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵtemplate(3, FilterGroupComponent_button_3_Template, 2, 8, "button", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "button", 4);
      i0.ɵɵlistener("click", function FilterGroupComponent_Template_button_click_4_listener() {
        return ctx.addFilterExpression();
      });
      i0.ɵɵtext(5);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(6, "button", 5);
      i0.ɵɵlistener("click", function FilterGroupComponent_Template_button_click_6_listener() {
        return ctx.addFilterGroup();
      });
      i0.ɵɵtext(7);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "button", 6);
      i0.ɵɵlistener("click", function FilterGroupComponent_Template_button_click_8_listener() {
        return ctx.removeFilterGroup();
      });
      i0.ɵɵelementEnd()()();
      i0.ɵɵtemplate(9, FilterGroupComponent_ul_9_Template, 2, 2, "ul", 7);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵattribute("aria-label", ctx.messageFor("filterToolbarAriaLabel"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.operators);
      i0.ɵɵadvance();
      i0.ɵɵproperty("title", ctx.messageFor("addFilter"))("svgIcon", ctx.filterAddExpressionIcon);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.messageFor("addFilter"), " ");
      i0.ɵɵadvance();
      i0.ɵɵproperty("title", ctx.messageFor("addGroup"))("svgIcon", ctx.filterAddGroupIcon);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.messageFor("addGroup"), " ");
      i0.ɵɵadvance();
      i0.ɵɵproperty("svgIcon", ctx.xIcon)("title", ctx.messageFor("remove"));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.currentItem.filters);
    }
  },
  dependencies: [i4.NgClass, i4.NgForOf, i4.NgIf, i6.ButtonComponent, FilterExpressionComponent, FilterGroupComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterGroupComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: FilterItem,
        useExisting: forwardRef(() => FilterGroupComponent)
      }],
      selector: 'kendo-filter-group',
      template: `
        <div class="k-filter-toolbar">
          <div class="k-toolbar k-toolbar-md" role="toolbar" [attr.aria-label]="messageFor('filterToolbarAriaLabel')" (mousedown)="onMouseDown($event)">
            <div class="k-toolbar-button-group k-button-group k-button-group-solid" role="group">
                <button
                    tabindex="-1"
                    *ngFor="let operator of operators"
                    kendoButton
                    class="k-toolbar-button"
                    [ngClass]="{'k-group-start': operator.value === 'and', 'k-group-end': operator.value === 'or'}"
                    [selected]="currentItem.logic === operator.value"
                    [attr.aria-pressed]="currentItem.logic === operator.value"
                    [title]="operator.text"
                    (click)="selectedChange(operator.value)"
                >
                    {{getOperator(operator.value)}}
                </button>
            </div>
            
            <button
                kendoButton
                class="k-toolbar-button"
                tabindex="-1"
                [title]="messageFor('addFilter')"
                icon="filter-add-expression"
                [svgIcon]="filterAddExpressionIcon"
                (click)="addFilterExpression()">
                {{messageFor('addFilter')}}
            </button>
          
            <button
                kendoButton
                class="k-toolbar-button"
                tabindex="-1"
                [title]="messageFor('addGroup')"
                icon="filter-add-group"
                [svgIcon]="filterAddGroupIcon"
                (click)="addFilterGroup()">
                {{messageFor('addGroup')}}
            </button>

            <button
                kendoButton
                class="k-toolbar-button"
                tabindex="-1"
                icon="x"
                [svgIcon]="xIcon"
                fillMode="flat"
                [title]="messageFor('remove')"
                (click)="removeFilterGroup()">
            </button>
        </div>
      </div>

      <ul class="k-filter-lines" role="group" *ngIf="currentItem.filters">
        <ng-container *ngFor="let item of currentItem.filters; let i = index; trackBy: trackByFunction">
          <li class="k-filter-item" role="treeitem" *ngIf="!item['filters']">
            <kendo-filter-expression (valueChange)="handleExpressionValueChange($event)" [currentItem]="item" [index]="i">
            </kendo-filter-expression>
          </li>
          <li class="k-filter-item" role="treeitem" *ngIf="item['filters']">
            <kendo-filter-group
                (valueChange)="valueChange.emit($event)"
                [currentItem]="item"
                [index]="i"
                >
            </kendo-filter-group>
          </li>
        </ng-container>
      </ul>
  `
    }]
  }], function () {
    return [{
      type: FilterService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: NavigationService
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.Renderer2
    }];
  }, {
    _filterItems: [{
      type: ViewChildren,
      args: [FilterItem]
    }],
    currentItem: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class Messages extends ComponentMessages {}
Messages.ɵfac = /* @__PURE__ */(() => {
  let ɵMessages_BaseFactory;
  return function Messages_Factory(t) {
    return (ɵMessages_BaseFactory || (ɵMessages_BaseFactory = i0.ɵɵgetInheritedFactory(Messages)))(t || Messages);
  };
})();
Messages.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Messages,
  selectors: [["", "kendoFilterMessages", ""]],
  inputs: {
    filterExpressionOperators: "filterExpressionOperators",
    filterExpressionFilters: "filterExpressionFilters",
    remove: "remove",
    addGroup: "addGroup",
    addFilter: "addFilter",
    filterAndLogic: "filterAndLogic",
    filterOrLogic: "filterOrLogic",
    filterEqOperator: "filterEqOperator",
    filterNotEqOperator: "filterNotEqOperator",
    filterIsNullOperator: "filterIsNullOperator",
    filterIsNotNullOperator: "filterIsNotNullOperator",
    filterIsEmptyOperator: "filterIsEmptyOperator",
    filterIsNotEmptyOperator: "filterIsNotEmptyOperator",
    filterStartsWithOperator: "filterStartsWithOperator",
    filterContainsOperator: "filterContainsOperator",
    filterNotContainsOperator: "filterNotContainsOperator",
    filterEndsWithOperator: "filterEndsWithOperator",
    filterGteOperator: "filterGteOperator",
    filterGtOperator: "filterGtOperator",
    filterLteOperator: "filterLteOperator",
    filterLtOperator: "filterLtOperator",
    filterIsTrue: "filterIsTrue",
    filterIsFalse: "filterIsFalse",
    filterBooleanAll: "filterBooleanAll",
    filterAfterOrEqualOperator: "filterAfterOrEqualOperator",
    filterAfterOperator: "filterAfterOperator",
    filterBeforeOperator: "filterBeforeOperator",
    filterBeforeOrEqualOperator: "filterBeforeOrEqualOperator",
    editorNumericDecrement: "editorNumericDecrement",
    editorNumericIncrement: "editorNumericIncrement",
    editorDateTodayText: "editorDateTodayText",
    editorDateToggleText: "editorDateToggleText",
    filterFieldAriaLabel: "filterFieldAriaLabel",
    filterOperatorAriaLabel: "filterOperatorAriaLabel",
    filterValueAriaLabel: "filterValueAriaLabel",
    filterToolbarAriaLabel: "filterToolbarAriaLabel",
    filterComponentAriaLabel: "filterComponentAriaLabel"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Messages, [{
    type: Directive,
    args: [{
      selector: '[kendoFilterMessages]'
    }]
  }], null, {
    filterExpressionOperators: [{
      type: Input
    }],
    filterExpressionFilters: [{
      type: Input
    }],
    remove: [{
      type: Input
    }],
    addGroup: [{
      type: Input
    }],
    addFilter: [{
      type: Input
    }],
    filterAndLogic: [{
      type: Input
    }],
    filterOrLogic: [{
      type: Input
    }],
    filterEqOperator: [{
      type: Input
    }],
    filterNotEqOperator: [{
      type: Input
    }],
    filterIsNullOperator: [{
      type: Input
    }],
    filterIsNotNullOperator: [{
      type: Input
    }],
    filterIsEmptyOperator: [{
      type: Input
    }],
    filterIsNotEmptyOperator: [{
      type: Input
    }],
    filterStartsWithOperator: [{
      type: Input
    }],
    filterContainsOperator: [{
      type: Input
    }],
    filterNotContainsOperator: [{
      type: Input
    }],
    filterEndsWithOperator: [{
      type: Input
    }],
    filterGteOperator: [{
      type: Input
    }],
    filterGtOperator: [{
      type: Input
    }],
    filterLteOperator: [{
      type: Input
    }],
    filterLtOperator: [{
      type: Input
    }],
    filterIsTrue: [{
      type: Input
    }],
    filterIsFalse: [{
      type: Input
    }],
    filterBooleanAll: [{
      type: Input
    }],
    filterAfterOrEqualOperator: [{
      type: Input
    }],
    filterAfterOperator: [{
      type: Input
    }],
    filterBeforeOperator: [{
      type: Input
    }],
    filterBeforeOrEqualOperator: [{
      type: Input
    }],
    editorNumericDecrement: [{
      type: Input
    }],
    editorNumericIncrement: [{
      type: Input
    }],
    editorDateTodayText: [{
      type: Input
    }],
    editorDateToggleText: [{
      type: Input
    }],
    filterFieldAriaLabel: [{
      type: Input
    }],
    filterOperatorAriaLabel: [{
      type: Input
    }],
    filterValueAriaLabel: [{
      type: Input
    }],
    filterToolbarAriaLabel: [{
      type: Input
    }],
    filterComponentAriaLabel: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class LocalizedMessagesDirective extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedMessagesDirective.ɵfac = function LocalizedMessagesDirective_Factory(t) {
  return new (t || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LocalizedMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedMessagesDirective,
  selectors: [["", "kendoFilterLocalizedMessages", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => LocalizedMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: '[kendoFilterLocalizedMessages]'
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * Represents the [Kendo UI Filter component for Angular]({% slug overview_filter %}).
 * The Filter component can be used to apply any user defined filter criteria.
 * @example
 * ```ts
 *   @Component({
 *       selector: 'my-app',
 *       template: `
 *          <kendo-filter [filters]="filters" (valueChange)="onValueChange($event)"></kendo-filter>
 *       `
 *   })
 *   export class AppComponent {
 *
 *      public filters: FilterExpression[] = [
 *          {
 *              name: 'country',
 *              label: 'Country',
 *              filter: 'string',
 *              operators: ['eq', 'neq'],
 *          },
 *          {
 *              name: 'budget',
 *              filter: 'number'
 *          }
 *      ];
 *
 *      onValueChange(e: CompositeFilterDescriptor){
 *          console.log(e)
 *      }
 *  }
 * ```
 */
class FilterComponent {
  constructor(filterService, localization, cdr, element, navigationService, renderer) {
    this.filterService = filterService;
    this.localization = localization;
    this.cdr = cdr;
    this.element = element;
    this.navigationService = navigationService;
    this.renderer = renderer;
    /**
     * Fires every time the Filter component value is updated.
     * That is each time a Filter Group or Filter Expression is added, removed, or updated.
     */
    this.valueChange = new EventEmitter();
    this._value = {
      filters: [],
      logic: 'and'
    };
    this._currentFilter = {
      logic: 'and',
      filters: []
    };
    validatePackage(packageMetadata);
    this.direction = localization.rtl ? 'rtl' : 'ltr';
  }
  /**
   * @hidden
   */
  focusout() {
    setTimeout(() => {
      if (!document.activeElement.closest('.k-filter')) {
        this.renderer.setAttribute(this.navigationService.currentlyFocusedElement, 'tabindex', '-1');
        this.navigationService.currentlyFocusedElement = this.navigationService.flattenFilterItems[this.navigationService.currentToolbarItemIndex].focusableChildren[0];
        this.renderer.setAttribute(this.navigationService.currentlyFocusedElement, 'tabindex', '0');
        this.navigationService.isInnerNavigationActivated = false;
        this.navigationService.isFilterExpressionComponentFocused = false;
      }
    });
  }
  /**
   * @hidden
   */
  onKeydown(event) {
    const keyCode = event.keyCode;
    const keys = [Keys.ArrowUp, Keys.ArrowDown, Keys.ArrowLeft, Keys.ArrowRight, Keys.Enter, Keys.Escape, Keys.Tab];
    if (keys.indexOf(keyCode) > -1) {
      this.navigationService.processKeyDown(keyCode, event);
    }
  }
  /**
   * Specifies the available user-defined filters. At least one filter should be provided.
   */
  set filters(_filters) {
    if (_filters.length > 0) {
      this.filterService.filters = _filters.map(filterExpression => {
        const clonedFilter = Object.assign({}, filterExpression);
        if (!clonedFilter.title) {
          clonedFilter.title = clonedFilter.field;
        }
        return clonedFilter;
      });
      this.setValue(this.value);
    }
  }
  get filters() {
    return this.filterService.filters;
  }
  /**
   * Sets the initial `value` of the Filter component.
   */
  set value(value) {
    const clonedValue = JSON.parse(JSON.stringify(value));
    if (this.filtersTypeChanged(this.value, clonedValue)) {
      // due to tracking group items by index, the filters should first be set to [] when the value is changed programmatically
      this.currentFilter.filters = [];
      this.cdr.detectChanges();
    }
    this._value = clonedValue;
    if (this.filters.length > 0) {
      this.setValue(this.value);
    }
  }
  get value() {
    return this._value;
  }
  get filterItems() {
    return this._filterItems.toArray();
  }
  get toolbarElement() {
    return this.element.nativeElement.querySelector('.k-toolbar');
  }
  ngOnInit() {
    this.localizationSubscription = this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
      this.cdr.detectChanges();
    });
  }
  ngAfterViewInit() {
    this.filterFieldsChanged();
    this.filterFieldsSubscription = this.filterFields.changes.subscribe(this.filterFieldsChanged.bind(this));
  }
  ngOnDestroy() {
    if (this.localizationSubscription) {
      this.localizationSubscription.unsubscribe();
    }
    if (this.filterFieldsSubscription) {
      this.filterFieldsSubscription.unsubscribe();
    }
  }
  filterFieldsChanged() {
    if (this.filterFields && this.filterFields.length > 0) {
      this.filters = this.filterFields.map(filterField => ({
        ...filterField,
        title: filterField.title,
        editorTemplate: filterField.editorTemplate?.templateRef
      }));
    }
    if (this.filters.length === 0) {
      throw new Error(FilterErrorMessages.missingFilters);
    }
    this.navigationService.reset(this.filterItems);
    if (!this.navigationService.currentlyFocusedElement) {
      const firstElement = this.navigationService.flattenFilterItems[0].focusableChildren[0];
      this.navigationService.currentlyFocusedElement = firstElement;
      this.renderer.setAttribute(firstElement, 'tabindex', '0');
    }
  }
  /**
   * @hidden
   */
  get currentFilter() {
    return this._currentFilter;
  }
  /**
   * @hidden
   */
  set currentFilter(value) {
    this._currentFilter = value;
  }
  /**
   * @hidden
   */
  onValueChange(isRemoveOperation) {
    this.cdr.detectChanges();
    this.valueChange.emit(this.filterService.normalizedValue);
    this.navigationService.reset(this.filterItems);
    if (isRemoveOperation) {
      if (this.navigationService.currentToolbarItemIndex === this.navigationService.flattenFilterItems.length) {
        this.navigationService.currentToolbarItemIndex -= 1;
      }
      this.navigationService.isFilterExpressionComponentFocused = false;
      const itemIndex = this.navigationService.currentToolbarItemIndex;
      const toolbarItem = this.navigationService.flattenFilterItems[itemIndex];
      const activeChildIndex = toolbarItem.focusableChildren.length - 1;
      this.navigationService.currentlyFocusedElement = toolbarItem.focusableChildren[activeChildIndex];
      this.renderer.setAttribute(this.navigationService.currentlyFocusedElement, 'tabindex', '0');
      this.renderer.addClass(this.navigationService.currentlyFocusedElement, 'k-focus');
      this.navigationService.currentlyFocusedElement.focus();
    }
  }
  normalizeFilter(filterDescriptor) {
    const foundFilter = this.filterService.filters.find(filter => filter.field === filterDescriptor.field);
    if (isDevMode() && !foundFilter) {
      throw new Error(FilterErrorMessages.missingFilterForUsedField(filterDescriptor.field));
    }
    if (isDevMode() && foundFilter.editor === 'boolean' && !filterDescriptor.value && filterDescriptor.value !== false) {
      console.warn(FilterErrorMessages.missingValueForBooleanField(filterDescriptor.field));
    }
    if (isDevMode() && foundFilter.editor === 'boolean' && filterDescriptor.operator !== 'eq') {
      console.warn(FilterErrorMessages.operatorBooleanField(filterDescriptor.field));
    }
    if (filterDescriptor.operator && foundFilter.operators && !foundFilter.operators.some(operator => operator === filterDescriptor.operator)) {
      throw new Error(FilterErrorMessages.filterMissingUsedOperator(filterDescriptor.field, filterDescriptor.operator));
    }
    if (foundFilter.editor === 'boolean') {
      filterDescriptor.operator = 'eq';
    }
    if (foundFilter.editor === 'date' && filterDescriptor.value) {
      filterDescriptor.value = new Date(filterDescriptor.value);
    }
    if (!isPresent(filterDescriptor.value)) {
      filterDescriptor.value = null;
    }
    if (nullOperators.indexOf(filterDescriptor.operator) >= 0) {
      filterDescriptor.value = null;
    }
  }
  setValue(items) {
    this.normalizeValue(items);
    this.filterService.normalizedValue = items;
    this.currentFilter = items;
    this.cdr.detectChanges();
    this.navigationService.reset(this.filterItems);
  }
  normalizeValue(items) {
    if (!this.filterService.filters.length) {
      return;
    }
    items.filters.forEach(item => {
      if (item.filters) {
        this.normalizeValue(item);
      } else {
        this.normalizeFilter(item);
      }
    });
  }
  /**
   * @hidden
   */
  messageFor(key) {
    return this.localization.get(key);
  }
  /**
   * @hidden
   */
  filtersTypeChanged(previousValue, newValue) {
    if (!previousValue?.filters && !newValue?.filters) {
      return previousValue?.operator != newValue?.operator || previousValue?.field != newValue?.field;
    }
    if (!previousValue?.filters || !newValue?.filters) {
      return true;
    }
    const previousFilters = previousValue.filters;
    const newFilters = newValue.filters;
    if (previousFilters.length !== newFilters.length) {
      return true;
    }
    for (let i = 0; i < previousFilters.length; i++) {
      if (this.filtersTypeChanged(previousFilters[i], newFilters[i])) {
        return true;
      }
    }
    return false;
  }
}
FilterComponent.ɵfac = function FilterComponent_Factory(t) {
  return new (t || FilterComponent)(i0.ɵɵdirectiveInject(FilterService), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(i0.Renderer2));
};
FilterComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilterComponent,
  selectors: [["kendo-filter"]],
  contentQueries: function FilterComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, FilterFieldComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filterFields = _t);
    }
  },
  viewQuery: function FilterComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(FilterItem, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._filterItems = _t);
    }
  },
  hostVars: 1,
  hostBindings: function FilterComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusout", function FilterComponent_focusout_HostBindingHandler($event) {
        return ctx.focusout($event);
      })("keydown", function FilterComponent_keydown_HostBindingHandler($event) {
        return ctx.onKeydown($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction);
    }
  },
  inputs: {
    filters: "filters",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.filter'
  }, FilterService, NavigationService])],
  decls: 5,
  vars: 3,
  consts: () => {
    let i18n_0;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Today button of the Date editor
       * @meaning kendo.filter.editorDateTodayText
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_0 = goog.getMsg("Today");
      i18n_0 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_0;
    } else {
      i18n_0 = $localize`:kendo.filter.editorDateTodayText|The text of the Today button of the Date editor:Today`;
    }
    let i18n_1;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The title of the Toggle button of the Date editor.
       * @meaning kendo.filter.editorDateToggleText
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_1 = goog.getMsg("Toggle calendar");
      i18n_1 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_1;
    } else {
      i18n_1 = $localize`:kendo.filter.editorDateToggleText|The title of the Toggle button of the Date editor.:Toggle calendar`;
    }
    let i18n_2;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The title of the Decrement button of the Numeric editor
       * @meaning kendo.filter.editorNumericDecrement
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_2 = goog.getMsg("Decrement");
      i18n_2 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_2;
    } else {
      i18n_2 = $localize`:kendo.filter.editorNumericDecrement|The title of the Decrement button of the Numeric editor:Decrement`;
    }
    let i18n_3;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The title of the Increment button of the Numeric editor
       * @meaning kendo.filter.editorNumericIncrement
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_3 = goog.getMsg("Increment");
      i18n_3 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_3;
    } else {
      i18n_3 = $localize`:kendo.filter.editorNumericIncrement|The title of the Increment button of the Numeric editor:Increment`;
    }
    let i18n_4;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Filter Expression Operators drop down
       * @meaning kendo.filter.filterExpressionOperators
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_4 = goog.getMsg("Operators");
      i18n_4 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_4;
    } else {
      i18n_4 = $localize`:kendo.filter.filterExpressionOperators|The text of the Filter Expression Operators drop down:Operators`;
    }
    let i18n_5;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Filter Expression filters drop down
       * @meaning kendo.filter.filterExpressionFilters
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_5 = goog.getMsg("Fields");
      i18n_5 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_5;
    } else {
      i18n_5 = $localize`:kendo.filter.filterExpressionFilters|The text of the Filter Expression filters drop down:Fields`;
    }
    let i18n_6;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Remove button
       * @meaning kendo.filter.remove
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_6 = goog.getMsg("Remove");
      i18n_6 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_6;
    } else {
      i18n_6 = $localize`:kendo.filter.remove|The text of the Remove button:Remove`;
    }
    let i18n_7;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Add Filter button
       * @meaning kendo.filter.addFilter
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_7 = goog.getMsg("Add Filter");
      i18n_7 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_7;
    } else {
      i18n_7 = $localize`:kendo.filter.addFilter|The text of the Add Filter button:Add Filter`;
    }
    let i18n_8;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Add Group button
       * @meaning kendo.filter.addGroup
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_8 = goog.getMsg("Add Group");
      i18n_8 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_8;
    } else {
      i18n_8 = $localize`:kendo.filter.addGroup|The text of the Add Group button:Add Group`;
    }
    let i18n_9;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the And filter logic
       * @meaning kendo.filter.filterAndLogic
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_9 = goog.getMsg("And");
      i18n_9 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_9;
    } else {
      i18n_9 = $localize`:kendo.filter.filterAndLogic|The text of the And filter logic:And`;
    }
    let i18n_10;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the Or filter logic
       * @meaning kendo.filter.filterOrLogic
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_10 = goog.getMsg("Or");
      i18n_10 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_10;
    } else {
      i18n_10 = $localize`:kendo.filter.filterOrLogic|The text of the Or filter logic:Or`;
    }
    let i18n_11;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the equal filter operator
       * @meaning kendo.filter.filterEqOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_11 = goog.getMsg("Is equal to");
      i18n_11 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_11;
    } else {
      i18n_11 = $localize`:kendo.filter.filterEqOperator|The text of the equal filter operator:Is equal to`;
    }
    let i18n_12;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the not equal filter operator
       * @meaning kendo.filter.filterNotEqOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_12 = goog.getMsg("Is not equal to");
      i18n_12 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_12;
    } else {
      i18n_12 = $localize`:kendo.filter.filterNotEqOperator|The text of the not equal filter operator:Is not equal to`;
    }
    let i18n_13;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the is null filter operator
       * @meaning kendo.filter.filterIsNullOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_13 = goog.getMsg("Is null");
      i18n_13 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_13;
    } else {
      i18n_13 = $localize`:kendo.filter.filterIsNullOperator|The text of the is null filter operator:Is null`;
    }
    let i18n_14;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the is not null filter operator
       * @meaning kendo.filter.filterIsNotNullOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_14 = goog.getMsg("Is not null");
      i18n_14 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_14;
    } else {
      i18n_14 = $localize`:kendo.filter.filterIsNotNullOperator|The text of the is not null filter operator:Is not null`;
    }
    let i18n_15;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the is empty filter operator
       * @meaning kendo.filter.filterIsEmptyOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_15 = goog.getMsg("Is empty");
      i18n_15 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_15;
    } else {
      i18n_15 = $localize`:kendo.filter.filterIsEmptyOperator|The text of the is empty filter operator:Is empty`;
    }
    let i18n_16;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the is not empty filter operator
       * @meaning kendo.filter.filterIsNotEmptyOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_16 = goog.getMsg("Is not empty");
      i18n_16 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_16;
    } else {
      i18n_16 = $localize`:kendo.filter.filterIsNotEmptyOperator|The text of the is not empty filter operator:Is not empty`;
    }
    let i18n_17;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the starts with filter operator
       * @meaning kendo.filter.filterStartsWithOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_17 = goog.getMsg("Starts with");
      i18n_17 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_17;
    } else {
      i18n_17 = $localize`:kendo.filter.filterStartsWithOperator|The text of the starts with filter operator:Starts with`;
    }
    let i18n_18;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the contains filter operator
       * @meaning kendo.filter.filterContainsOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_18 = goog.getMsg("Contains");
      i18n_18 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_18;
    } else {
      i18n_18 = $localize`:kendo.filter.filterContainsOperator|The text of the contains filter operator:Contains`;
    }
    let i18n_19;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the does not contain filter operator
       * @meaning kendo.filter.filterNotContainsOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_19 = goog.getMsg("Does not contain");
      i18n_19 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_19;
    } else {
      i18n_19 = $localize`:kendo.filter.filterNotContainsOperator|The text of the does not contain filter operator:Does not contain`;
    }
    let i18n_20;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the ends with filter operator
       * @meaning kendo.filter.filterEndsWithOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_20 = goog.getMsg("Ends with");
      i18n_20 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_20;
    } else {
      i18n_20 = $localize`:kendo.filter.filterEndsWithOperator|The text of the ends with filter operator:Ends with`;
    }
    let i18n_21;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the greater than or equal filter operator
       * @meaning kendo.filter.filterGteOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_21 = goog.getMsg("Is greater than or equal to");
      i18n_21 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_21;
    } else {
      i18n_21 = $localize`:kendo.filter.filterGteOperator|The text of the greater than or equal filter operator:Is greater than or equal to`;
    }
    let i18n_22;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the greater than filter operator
       * @meaning kendo.filter.filterGtOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_22 = goog.getMsg("Is greater than");
      i18n_22 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_22;
    } else {
      i18n_22 = $localize`:kendo.filter.filterGtOperator|The text of the greater than filter operator:Is greater than`;
    }
    let i18n_23;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the less than or equal filter operator
       * @meaning kendo.filter.filterLteOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_23 = goog.getMsg("Is less than or equal to");
      i18n_23 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_23;
    } else {
      i18n_23 = $localize`:kendo.filter.filterLteOperator|The text of the less than or equal filter operator:Is less than or equal to`;
    }
    let i18n_24;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the less than filter operator
       * @meaning kendo.filter.filterLtOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_24 = goog.getMsg("Is less than");
      i18n_24 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_24;
    } else {
      i18n_24 = $localize`:kendo.filter.filterLtOperator|The text of the less than filter operator:Is less than`;
    }
    let i18n_25;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the IsTrue boolean filter option
       * @meaning kendo.filter.filterIsTrue
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_25 = goog.getMsg("Is True");
      i18n_25 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_25;
    } else {
      i18n_25 = $localize`:kendo.filter.filterIsTrue|The text of the IsTrue boolean filter option:Is True`;
    }
    let i18n_26;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the IsFalse boolean filter option
       * @meaning kendo.filter.filterIsFalse
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_26 = goog.getMsg("Is False");
      i18n_26 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_26;
    } else {
      i18n_26 = $localize`:kendo.filter.filterIsFalse|The text of the IsFalse boolean filter option:Is False`;
    }
    let i18n_27;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the (All) boolean filter option
       * @meaning kendo.filter.filterBooleanAll
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_27 = goog.getMsg("(All)");
      i18n_27 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_27;
    } else {
      i18n_27 = $localize`:kendo.filter.filterBooleanAll|The text of the (All) boolean filter option:(All)`;
    }
    let i18n_28;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the after or equal date filter operator
       * @meaning kendo.filter.filterAfterOrEqualOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_28 = goog.getMsg("Is after or equal to");
      i18n_28 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_28;
    } else {
      i18n_28 = $localize`:kendo.filter.filterAfterOrEqualOperator|The text of the after or equal date filter operator:Is after or equal to`;
    }
    let i18n_29;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the after date filter operator
       * @meaning kendo.filter.filterAfterOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_29 = goog.getMsg("Is after");
      i18n_29 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_29;
    } else {
      i18n_29 = $localize`:kendo.filter.filterAfterOperator|The text of the after date filter operator:Is after`;
    }
    let i18n_30;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the before date filter operator
       * @meaning kendo.filter.filterBeforeOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_30 = goog.getMsg("Is before");
      i18n_30 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_30;
    } else {
      i18n_30 = $localize`:kendo.filter.filterBeforeOperator|The text of the before date filter operator:Is before`;
    }
    let i18n_31;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the before or equal date filter operator
       * @meaning kendo.filter.filterBeforeOrEqualOperator
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_31 = goog.getMsg("Is before or equal to");
      i18n_31 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_31;
    } else {
      i18n_31 = $localize`:kendo.filter.filterBeforeOrEqualOperator|The text of the before or equal date filter operator:Is before or equal to`;
    }
    let i18n_32;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the filter field aria label
       * @meaning kendo.filter.filterFieldAriaLabel
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_32 = goog.getMsg("field");
      i18n_32 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_32;
    } else {
      i18n_32 = $localize`:kendo.filter.filterFieldAriaLabel|The text of the filter field aria label:field`;
    }
    let i18n_33;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the filter operator aria label
       * @meaning kendo.filter.filterOperatorAriaLabel
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_33 = goog.getMsg("operator");
      i18n_33 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_33;
    } else {
      i18n_33 = $localize`:kendo.filter.filterOperatorAriaLabel|The text of the filter operator aria label:operator`;
    }
    let i18n_34;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the filter value aria label
       * @meaning kendo.filter.filterValueAriaLabel
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_34 = goog.getMsg("value");
      i18n_34 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_34;
    } else {
      i18n_34 = $localize`:kendo.filter.filterValueAriaLabel|The text of the filter value aria label:value`;
    }
    let i18n_35;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the filter row aria label
       * @meaning kendo.filter.filterToolbarAriaLabel
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_35 = goog.getMsg("filter row settings");
      i18n_35 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_35;
    } else {
      i18n_35 = $localize`:kendo.filter.filterToolbarAriaLabel|The text of the filter row aria label:filter row settings`;
    }
    let i18n_36;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the filter component aria label
       * @meaning kendo.filter.filterComponentAriaLabel
       */
      const MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_36 = goog.getMsg("filter component");
      i18n_36 = MSG__USERS_PRIYANKA_DOCUMENTS_PROGRAM_APP_APPS_NODE_MODULES__PROGRESS_KENDO_ANGULAR_FILTER_FESM2020_PROGRESS_KENDO_ANGULAR_FILTER_MJS_36;
    } else {
      i18n_36 = $localize`:kendo.filter.filterComponentAriaLabel|The text of the filter component aria label:filter component`;
    }
    return [["kendoFilterLocalizedMessages", "", "editorDateTodayText", i18n_0, "editorDateToggleText", i18n_1, "editorNumericDecrement", i18n_2, "editorNumericIncrement", i18n_3, "filterExpressionOperators", i18n_4, "filterExpressionFilters", i18n_5, "remove", i18n_6, "addFilter", i18n_7, "addGroup", i18n_8, "filterAndLogic", i18n_9, "filterOrLogic", i18n_10, "filterEqOperator", i18n_11, "filterNotEqOperator", i18n_12, "filterIsNullOperator", i18n_13, "filterIsNotNullOperator", i18n_14, "filterIsEmptyOperator", i18n_15, "filterIsNotEmptyOperator", i18n_16, "filterStartsWithOperator", i18n_17, "filterContainsOperator", i18n_18, "filterNotContainsOperator", i18n_19, "filterEndsWithOperator", i18n_20, "filterGteOperator", i18n_21, "filterGtOperator", i18n_22, "filterLteOperator", i18n_23, "filterLtOperator", i18n_24, "filterIsTrue", i18n_25, "filterIsFalse", i18n_26, "filterBooleanAll", i18n_27, "filterAfterOrEqualOperator", i18n_28, "filterAfterOperator", i18n_29, "filterBeforeOperator", i18n_30, "filterBeforeOrEqualOperator", i18n_31, "filterFieldAriaLabel", i18n_32, "filterOperatorAriaLabel", i18n_33, "filterValueAriaLabel", i18n_34, "filterToolbarAriaLabel", i18n_35, "filterComponentAriaLabel", i18n_36], [1, "k-filter"], ["role", "tree", 1, "k-filter-container"], ["role", "treeitem", 1, "k-filter-group-main"], [3, "valueChange", "currentItem"]];
  },
  template: function FilterComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "div", 1)(2, "ul", 2)(3, "li", 3)(4, "kendo-filter-group", 4);
      i0.ɵɵlistener("valueChange", function FilterComponent_Template_kendo_filter_group_valueChange_4_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵattribute("dir", ctx.direction);
      i0.ɵɵadvance();
      i0.ɵɵattribute("aria-label", ctx.messageFor("filterComponentAriaLabel"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("currentItem", ctx.currentFilter);
    }
  },
  dependencies: [FilterGroupComponent, LocalizedMessagesDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterComponent, [{
    type: Component,
    args: [{
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.filter'
      }, FilterService, NavigationService],
      selector: 'kendo-filter',
      template: `
        <ng-container kendoFilterLocalizedMessages
            i18n-editorDateTodayText="kendo.filter.editorDateTodayText|The text of the Today button of the Date editor"
            editorDateTodayText="Today"

            i18n-editorDateToggleText="kendo.filter.editorDateToggleText|The title of the Toggle button of the Date editor."
            editorDateToggleText="Toggle calendar"

            i18n-editorNumericDecrement="kendo.filter.editorNumericDecrement|The title of the Decrement button of the Numeric editor"
            editorNumericDecrement="Decrement"

            i18n-editorNumericIncrement="kendo.filter.editorNumericIncrement|The title of the Increment button of the Numeric editor"
            editorNumericIncrement="Increment"

            i18n-filterExpressionOperators="kendo.filter.filterExpressionOperators|The text of the Filter Expression Operators drop down"
            filterExpressionOperators="Operators"

            i18n-filterExpressionFilters="kendo.filter.filterExpressionFilters|The text of the Filter Expression filters drop down"
            filterExpressionFilters="Fields"

            i18n-remove="kendo.filter.remove|The text of the Remove button"
            remove="Remove"

            i18n-addFilter="kendo.filter.addFilter|The text of the Add Filter button"
            addFilter="Add Filter"

            i18n-addGroup="kendo.filter.addGroup|The text of the Add Group button"
            addGroup="Add Group"

            i18n-filterAndLogic="kendo.filter.filterAndLogic|The text of the And filter logic"
            filterAndLogic="And"

            i18n-filterOrLogic="kendo.filter.filterOrLogic|The text of the Or filter logic"
            filterOrLogic="Or"

            i18n-filterEqOperator="kendo.filter.filterEqOperator|The text of the equal filter operator"
            filterEqOperator="Is equal to"

            i18n-filterNotEqOperator="kendo.filter.filterNotEqOperator|The text of the not equal filter operator"
            filterNotEqOperator="Is not equal to"

            i18n-filterIsNullOperator="kendo.filter.filterIsNullOperator|The text of the is null filter operator"
            filterIsNullOperator="Is null"

            i18n-filterIsNotNullOperator="kendo.filter.filterIsNotNullOperator|The text of the is not null filter operator"
            filterIsNotNullOperator="Is not null"

            i18n-filterIsEmptyOperator="kendo.filter.filterIsEmptyOperator|The text of the is empty filter operator"
            filterIsEmptyOperator="Is empty"

            i18n-filterIsNotEmptyOperator="kendo.filter.filterIsNotEmptyOperator|The text of the is not empty filter operator"
            filterIsNotEmptyOperator="Is not empty"

            i18n-filterStartsWithOperator="kendo.filter.filterStartsWithOperator|The text of the starts with filter operator"
            filterStartsWithOperator="Starts with"

            i18n-filterContainsOperator="kendo.filter.filterContainsOperator|The text of the contains filter operator"
            filterContainsOperator="Contains"

            i18n-filterNotContainsOperator="kendo.filter.filterNotContainsOperator|The text of the does not contain filter operator"
            filterNotContainsOperator="Does not contain"

            i18n-filterEndsWithOperator="kendo.filter.filterEndsWithOperator|The text of the ends with filter operator"
            filterEndsWithOperator="Ends with"

            i18n-filterGteOperator="kendo.filter.filterGteOperator|The text of the greater than or equal filter operator"
            filterGteOperator="Is greater than or equal to"

            i18n-filterGtOperator="kendo.filter.filterGtOperator|The text of the greater than filter operator"
            filterGtOperator="Is greater than"

            i18n-filterLteOperator="kendo.filter.filterLteOperator|The text of the less than or equal filter operator"
            filterLteOperator="Is less than or equal to"

            i18n-filterLtOperator="kendo.filter.filterLtOperator|The text of the less than filter operator"
            filterLtOperator="Is less than"

            i18n-filterIsTrue="kendo.filter.filterIsTrue|The text of the IsTrue boolean filter option"
            filterIsTrue="Is True"

            i18n-filterIsFalse="kendo.filter.filterIsFalse|The text of the IsFalse boolean filter option"
            filterIsFalse="Is False"

            i18n-filterBooleanAll="kendo.filter.filterBooleanAll|The text of the (All) boolean filter option"
            filterBooleanAll="(All)"

            i18n-filterAfterOrEqualOperator="kendo.filter.filterAfterOrEqualOperator|The text of the after or equal date filter operator"
            filterAfterOrEqualOperator="Is after or equal to"

            i18n-filterAfterOperator="kendo.filter.filterAfterOperator|The text of the after date filter operator"
            filterAfterOperator="Is after"

            i18n-filterBeforeOperator="kendo.filter.filterBeforeOperator|The text of the before date filter operator"
            filterBeforeOperator="Is before"

            i18n-filterBeforeOrEqualOperator="kendo.filter.filterBeforeOrEqualOperator|The text of the before or equal date filter operator"
            filterBeforeOrEqualOperator="Is before or equal to"

            i18n-filterFieldAriaLabel="kendo.filter.filterFieldAriaLabel|The text of the filter field aria label"
            filterFieldAriaLabel="field"

            i18n-filterOperatorAriaLabel="kendo.filter.filterOperatorAriaLabel|The text of the filter operator aria label"
            filterOperatorAriaLabel="operator"

            i18n-filterValueAriaLabel="kendo.filter.filterValueAriaLabel|The text of the filter value aria label"
            filterValueAriaLabel="value"

            i18n-filterToolbarAriaLabel="kendo.filter.filterToolbarAriaLabel|The text of the filter row aria label"
            filterToolbarAriaLabel="filter row settings"

            i18n-filterComponentAriaLabel="kendo.filter.filterComponentAriaLabel|The text of the filter component aria label"
            filterComponentAriaLabel="filter component"
            >
        </ng-container>
        <div class="k-filter" [attr.dir]="direction">
            <ul class='k-filter-container' role="tree" [attr.aria-label]="messageFor('filterComponentAriaLabel')">
                <li class='k-filter-group-main' role="treeitem">
                    <kendo-filter-group
                        [currentItem]="currentFilter"
                        (valueChange)="onValueChange($event)"
                        >
                    </kendo-filter-group>
                </li>
            </ul>
        </div>
    `
    }]
  }], function () {
    return [{
      type: FilterService
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: NavigationService
    }, {
      type: i0.Renderer2
    }];
  }, {
    focusout: [{
      type: HostListener,
      args: ['focusout', ['$event']]
    }],
    onKeydown: [{
      type: HostListener,
      args: ['keydown', ['$event']]
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    filters: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    filterFields: [{
      type: ContentChildren,
      args: [FilterFieldComponent]
    }],
    _filterItems: [{
      type: ViewChildren,
      args: [FilterItem]
    }]
  });
})();

/**
 * Custom component messages override default component messages
 * ([see example]({% slug globalization_filter %}#toc-localization)).
 */
class CustomMessagesComponent extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
  get override() {
    return true;
  }
}
CustomMessagesComponent.ɵfac = function CustomMessagesComponent_Factory(t) {
  return new (t || CustomMessagesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
CustomMessagesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CustomMessagesComponent,
  selectors: [["kendo-filter-messages"]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => CustomMessagesComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CustomMessagesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomMessagesComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => CustomMessagesComponent)
      }],
      selector: 'kendo-filter-messages',
      template: ``
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();
const importedModules = [CommonModule, InputsModule, LabelModule, DropDownsModule, ButtonsModule, DateInputsModule];
/**
 * @hidden
 */
class SharedModule {}
SharedModule.ɵfac = function SharedModule_Factory(t) {
  return new (t || SharedModule)();
};
SharedModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SharedModule
});
SharedModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [importedModules, CommonModule, InputsModule, LabelModule, DropDownsModule, ButtonsModule, DateInputsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      imports: [...importedModules],
      exports: [...importedModules]
    }]
  }], null, null);
})();

/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Filter component.
 *
 * @example
 *
 * ```
 * // Import the Filter module
 * import { FilterModule } from '@progress/kendo-angular-filter';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, FilterModule], // import Filter module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule { }
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class FilterModule {}
FilterModule.ɵfac = function FilterModule_Factory(t) {
  return new (t || FilterModule)();
};
FilterModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FilterModule
});
FilterModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterModule, [{
    type: NgModule,
    args: [{
      imports: [SharedModule],
      declarations: [FilterComponent, FilterNumericEditorComponent, FilterTextEditorComponent, FilterExpressionComponent, FilterGroupComponent, FilterExpressionOperatorsComponent, FilterBooleanEditorComponent, FilterDateEditorComponent, LocalizedMessagesDirective, CustomMessagesComponent, AriaLabelValueDirective, FilterFieldComponent, FilterValueEditorTemplateDirective],
      exports: [FilterComponent, FilterNumericEditorComponent, FilterTextEditorComponent, FilterExpressionComponent, FilterGroupComponent, FilterExpressionOperatorsComponent, FilterBooleanEditorComponent, FilterDateEditorComponent, LocalizedMessagesDirective, CustomMessagesComponent, AriaLabelValueDirective, FilterFieldComponent, FilterValueEditorTemplateDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AriaLabelValueDirective, CustomMessagesComponent, FilterBooleanEditorComponent, FilterComponent, FilterDateEditorComponent, FilterExpressionComponent, FilterExpressionOperatorsComponent, FilterFieldComponent, FilterGroupComponent, FilterModule, FilterNumericEditorComponent, FilterTextEditorComponent, FilterValueEditorTemplateDirective, LocalizedMessagesDirective };