import { Component, ViewEncapsulation } from '@angular/core';
import { CommonButtonRowWidget, CommonIconTextWidget } from '@eforall/common';
import { DashboardScrollableContentWidget } from '@eforall/dashboard';
import { DependencyType, GanttDependency, GanttModule, GanttTask } from "@progress/kendo-angular-gantt";


export interface Task {
	id: number;
	title: string;
	start: Date;
	end: Date;
	completionRatio?: number;
	type?: 'Implemented' | 'Testing' | 'Roll-out' | 'Comms';
	subtasks?: Task[];
}

@Component({
	selector: 'roadmap-example-page',
	standalone: true,
	imports: [
		CommonButtonRowWidget,
		CommonIconTextWidget,
		DashboardScrollableContentWidget,
		GanttModule,
	],
	encapsulation: ViewEncapsulation.None,
	styles: [
		`
      .k-task.implemented.k-task-milestone,
      .k-task.implemented .k-task-summary-complete,
      .k-task.implemented.k-task-summary,
      .k-task.implemented .k-task-complete,
      .k-task.implemented .k-task-template {
        background-color: yellowgreen;
        color: white;
      }
      .k-task.testing.k-task-summary,
      .k-task.testing .k-task-summary-complete,
      .k-task.testing .k-task-complete,
      .k-task.testing.k-task-milestone,
      .k-task.testing .k-task-template {
        background-color: gold;
        color: darkblue;
      }
      .k-task.roll-out.k-task-summary,
      .k-task.roll-out .k-task-summary-complete,
      .k-task.roll-out .k-task-complete,
      .k-task.roll-out.k-task-milestone,
      .k-task.roll-out .k-task-template {
        background-color: coral;
        color: white;
      }
      .k-task.comms.k-task-summary,
      .k-task.comms .k-task-summary-complete,
      .k-task.comms .k-task-complete,
      .k-task.comms.k-task-milestone,
      .k-task.comms .k-task-template {
        background-color: deepskyblue;
        color: white;
      }
    `,
	],
	templateUrl: './roadmap-example.page.html'
})
export class RoadmapExamplePage {
	public dependencies: GanttDependency[] = [
		{ id: 528, fromId: 18, toId: 11, type: DependencyType.FS },
		{ id: 527, fromId: 18, toId: 12, type: DependencyType.FS },
		{ id: 529, fromId: 12, toId: 13, type: DependencyType.FS },
	];

	public data: Task[] = [
		{
			id: 7,
			title: 'End of Quarterlies',
			start: new Date('2024-01-01T00:00:00.000Z'),
			end: new Date('2025-12-31T00:00:00.000Z'),
			completionRatio: 0.45708333333333334,
			subtasks: [
				{
					id: 18, title: 'Test with Virtual', start: new Date('2024-01-02T00:00:00.000Z'),
					end: new Date('2024-06-02T00:00:00.000Z'), completionRatio: 0.23, type: 'Testing'
				},
				{
					id: 11, title: 'Communication', start: new Date('2024-07-09T00:00:00.000Z'), type: 'Comms',
					end: new Date('2024-07-09T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 12, title: 'Roll-out', start: new Date('2024-07-02T00:00:00.000Z'), type: 'Roll-out',
					end: new Date('2024-12-10T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 13, title: 'Live', start: new Date('2025-01-01T00:00:00.000Z'),
					end: new Date('2025-12-31T00:00:00.000Z'), completionRatio: 0.77, type: 'Implemented',
				}]
		},
		{
			id: 8,
			title: 'Tri-annual Programs Calendar',
			start: new Date('2024-01-01T00:00:00.000Z'),
			end: new Date('2027-12-31T00:00:00.000Z'),
			completionRatio: 0.45708333333333334,
			subtasks: [
				{
					id: 28, title: 'Test with Virtual', start: new Date('2024-01-02T00:00:00.000Z'),
					end: new Date('2026-08-02T00:00:00.000Z'), completionRatio: 0.23, type: 'Testing'
				},
				{
					id: 21, title: 'Communication', start: new Date('2024-07-09T00:00:00.000Z'), type: 'Comms',
					end: new Date('2024-07-09T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 22, title: 'Roll-out', start: new Date('2026-08-10T00:00:00.000Z'), type: 'Roll-out',
					end: new Date('2027-08-10T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 23, title: 'Live', start: new Date('2027-09-01T00:00:00.000Z'),
					end: new Date('2027-12-31T00:00:00.000Z'), completionRatio: 0.77, type: 'Implemented',
				}]
		},
		{
			id: 9,
			title: 'eLearning',
			start: new Date('2025-01-01T00:00:00.000Z'),
			end: new Date('2027-12-31T00:00:00.000Z'),
			completionRatio: 0.45708333333333334,
			subtasks: [
				{
					id: 38, title: 'Test with Virtual and CO', start: new Date('2025-01-12T00:00:00.000Z'),
					end: new Date('2026-08-02T00:00:00.000Z'), completionRatio: 0.23, type: 'Testing'
				},
				{
					id: 31, title: 'Communication', start: new Date('2024-07-09T00:00:00.000Z'), type: 'Comms',
					end: new Date('2024-07-09T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 32, title: 'Roll-out', start: new Date('2026-08-10T00:00:00.000Z'), type: 'Roll-out',
					end: new Date('2027-08-10T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 33, title: 'Live', start: new Date('2027-09-01T00:00:00.000Z'),
					end: new Date('2027-12-31T00:00:00.000Z'), completionRatio: 0.77, type: 'Implemented',
				}]
		},
		{
			id: 4,
			title: 'Flipped Classroom',
			start: new Date('2025-01-01T00:00:00.000Z'),
			end: new Date('2027-12-31T00:00:00.000Z'),
			completionRatio: 0.45708333333333334,
			subtasks: [
				{
					id: 48, title: 'Test with Virtual and CO', start: new Date('2025-01-12T00:00:00.000Z'),
					end: new Date('2026-08-02T00:00:00.000Z'), completionRatio: 0.23, type: 'Testing'
				},
				{
					id: 41, title: 'Communication', start: new Date('2024-07-09T00:00:00.000Z'), type: 'Comms',
					end: new Date('2024-07-09T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 42, title: 'Roll-out', start: new Date('2026-08-10T00:00:00.000Z'), type: 'Roll-out',
					end: new Date('2027-08-10T00:00:00.000Z'), completionRatio: 0.5766666666666667,
				},
				{
					id: 43, title: 'Live', start: new Date('2027-09-01T00:00:00.000Z'),
					end: new Date('2027-12-31T00:00:00.000Z'), completionRatio: 0.77, type: 'Implemented',
				}]
		},
	];


	// Use an arrow function to capture the 'this' execution context of the class.
	public fetchChildren = (item: Task): Task[] => {
		if (item.subtasks?.length) return item.subtasks;
		return [];
	};

	public hasChildren = (item: Task): boolean => {
		if (item?.subtasks) return item?.subtasks?.length > 0;
		return false;
	};


	public taskClass(dataItem: Task): { [className: string]: boolean } {
		const type = dataItem?.type;
		return {
			"comms": type == 'Comms',
			"implemented": type == 'Implemented',
			"roll-out": type == 'Roll-out',
			"testing": type == 'Testing',
		};
	}
}