import { DashboardSectionMenu } from '@eforall/dashboard';

export const DEPARTMENT_MENU: DashboardSectionMenu = {
	label: 'Department',
	items: [
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-users-viewfinder',
			label: 'Departments',
			routerLink: '/departments',
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-chart-network',
			label: 'Functions',
			routerLink: '/functions',
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-people-group',
			label: 'Teams',
			routerLink: '/teams',
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-file-certificate',
			label: 'Policies',
			routerLink: '/policies',
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-list-ol',
			label: 'How Tos',
			routerLink: '/how-tos',
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-comment-question',
			label: 'FAQs',
			routerLink: '/faqs',
		},
	]
}