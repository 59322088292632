import { computed } from "@angular/core";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { GridSelectionManager } from "./grid-selection-manager";
import { DEFAULT_STATE, GridStateManager } from "./grid-state-manager";

export class GridFiltersManager<RowT extends { updatedUTC: number }> {

	public readonly current = computed<CompositeFilterDescriptor>(() => this.state.get().filter ?? DEFAULT_STATE.filter);


	constructor(
		private readonly state: GridStateManager<RowT>,
		private readonly selection: GridSelectionManager<RowT>,
	) {
	}

	
	public get hasFilters() {
		return !!this.state.get().filter;
	}


	public clearFilters() {
		this.state.merge({ filter: DEFAULT_STATE.filter });
	}


	public setFilters(filters: CompositeFilterDescriptor) {
		// // If the filter being set is exactly the same as the current filter, toggle it off
		this._setFilters(filters);
	}


	private _setFilters(filters: CompositeFilterDescriptor) {
		this.selection.clear();
		this.state.merge({ filter: filters || DEFAULT_STATE.filter });
	}


	public patchFilter(newFilter: FilterDescriptor) {

		if (!newFilter || !(typeof newFilter.field == 'string')) return;

		this.selection.clear();

		//
		// Get the current set of filters
		//
		let filters = this.state.get().filter;


		if (filters == undefined || filters.filters.length == 0) {
			//
			// No existing filters? Just set this one
			//
			this._setFilters({ filters: [newFilter], logic: 'and' });
		}
		else {

			// Make a copy so we aren't altering the filters in state
			filters = { ...filters };
			filters.filters = [...filters.filters];


			filters = this._eraseFiltersField([newFilter.field], filters);

			filters.logic = 'and';
			filters.filters.push({ filters: [newFilter], logic: 'and' });

			this._setFilters(filters);
		}
	}


	public patchCompositeFilter(fields: string[], newFilter: CompositeFilterDescriptor) {

		if (!newFilter) return;

		this.selection.clear();

		//
		// Get the current set of filters
		//
		let filters = this.state.get().filter;


		if (filters == undefined || filters.filters.length == 0) {
			//
			// No existing filters? Just set this one
			//
			this._setFilters(newFilter);
		}
		else {

			// Make a copy so we aren't altering the filters in state
			filters = { ...filters };

			filters = this._eraseFiltersField(fields, filters);

			filters.logic = 'and';
			filters.filters.push(newFilter);
			this._setFilters(filters);

		}
	}


	// Remove Filter
	public removeFilter(field: string) {
		this.removeFilters([field]);
	}


	public removeFilters(fields: string[]) {

		//
		// Get the current set of filters
		//
		let filters = this.state.get().filter;

		if (filters) {

			// Make a copy so we aren't altering the filters in state
			filters = { ...filters };
			filters.filters = [...filters.filters];

			filters = this._eraseFiltersField(fields, filters);

			this._setFilters(filters);
		}
	}


	//
	// Recursive function to remove filters
	//
	private _eraseFiltersField(fields: string[], filter: CompositeFilterDescriptor) {
		for (let i = 0; i < filter.filters.length; i++) {
			// For combination 'and' and 'or', kendo use nested filters so here is recursion

			let composite = filter.filters[i] as CompositeFilterDescriptor;
			const single = filter.filters[i] as FilterDescriptor;
			if (composite.filters) {
				composite = this._eraseFiltersField(fields, composite);
				if (Object.keys(composite).length === 0 || composite.filters.length == 0) {
					filter.filters.splice(i, 1);
					i--;
					continue;
				}
			}

			// Remove filters
			else if (single.field) {
				if (fields.find(field => field == single.field)) {
					filter.filters.splice(i, 1);
					i--;
					continue;
				}
			}
		}

		return filter;
	}

}