// export interface DashboardMenu {
// 	section: DashboardSectionMenu[],
// }



export interface DashboardSectionMenu {
	label: string,
	items: DashboardMenuSetOrItem[]
}

export type DashboardMenuSetOrItem = DashboardMenuSet | DashboardMenuItem;

export interface DashboardMenuSet {
	kind: 'set',
	label: string,
	iconStyle: 'fa-regular' | 'fa-solid',
	icon: string,
	menuItems: DashboardMenuItem[],
}

export interface DashboardMenuItem {
	kind: 'menuItem',
	label: string,
	iconStyle: 'fa-regular' | 'fa-solid',
	icon: string,
	routerLink?: string,
	url?: string,
}

export interface DashboardToolbarButton {
	key: string,
	icon: string,
	iconStyle: 'fa-regular' | 'fa-solid',
	tooltip: string,
	label: string,
	callback: () => Promise<void>,
}

export interface DashboardNotifications {
	text: string,
	link: string,
}


export interface DashboardFrameConfig {
	appName: string,
	includeBreadcrumb: boolean,
	menu: DashboardSectionMenu[],
	toolbarButtons: DashboardToolbarButton[],
	notifications: DashboardNotifications[],
}