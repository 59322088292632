import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ErrorService } from '@eforall/common';


@Component({
	selector: 'dashboard-error-generic-data-part',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './error-generic-data.part.html',
	styleUrls: ['./error-generic-data.part.scss']
})
export class DashboardErrorGenericDataPart implements OnInit {

	private router = inject(Router);
	private auth = inject(AuthService);
	private errorService = inject(ErrorService);

	background = 'transparent';
	activity? = '';
	name? = '';
	message? = '';
	stack? = '';

	canlogout = true;


	ngOnInit() {
		const details = this.errorService.snapshot;
		if (details.error) {
			this.background = 'darkred';
			this.activity = details.activity;
			this.name = details.name;
			this.message = details.message;
			this.stack = details.stack;
		}
		else {
			this.background = 'darkgreen';
			this.activity = 'Normal Activity';
			this.name = 'No Error';
			this.message = 'Everything is hunky dory!';
			this.stack = '';
		}
	}

	goBack() {
		this.errorService.clearError();
	}

	goToDashboard(): void {
		this.errorService.clearError();
		this.router.navigate(['/']);
	}

	logout() {
		this.errorService.clearError();
		this.auth.signOut();
		this.canlogout = false;
	}
}