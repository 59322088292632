import { Signal, computed, signal } from "@angular/core";
import { ColumnComponent, GridComponent, GroupableSettings } from "@progress/kendo-angular-grid";
import { GroupDescriptor } from "@progress/kendo-data-query";
import { Subscription } from "rxjs";
import { GridSetup } from "../../interfaces";
import { GridStateManager } from "./grid-state-manager";


export class GridGroupableManager<RowT extends { updatedUTC: number }> {

	protected readonly gridComponent = signal<GridComponent | undefined>(undefined);


	constructor(
		private readonly setup: Signal<GridSetup<RowT>>,
		private readonly state: GridStateManager<RowT>,
	) {
	}


	public settings = computed<GroupableSettings>(() => {

		return {
			emptyText: 'Drag a column header and drop it here',
			enabled: true,
			showFooter: false,
		};
	});


	private lastGroups = computed(() => this.state.get().group || []);

	private gridGroupsSubscription: Subscription | undefined = undefined;


	public setGridComponent(gridComponent: GridComponent | undefined) {
		// Remove subscription from prior grid
		if (this.gridComponent() && this.gridComponent() !== gridComponent) {
			this.gridGroupsSubscription?.unsubscribe();
		}

		this.gridComponent.set(gridComponent);

		if (gridComponent) {
			// Listen for group changes on this grid
			this.gridGroupsSubscription = gridComponent.groupChange.subscribe(this.groupChange.bind(this));
		}
	}


	private groupChange(groups: GroupDescriptor[]) {

		const changes: { [index: string]: string } = {};

		//
		// Assume all existing groups are being removed
		//
		for (const group of this.lastGroups()) {
			changes[group.field] = 'remove';
		}

		//
		// Any new ones are being added, if were there before then ignore
		//
		for (const group of groups) {
			if (changes[group.field]) changes[group.field] = 'ignore';
			else changes[group.field] = 'add';
		}

		const gridComponent = this.gridComponent();

		//
		// Hide columns as needed
		//
		for (const field of Object.keys(changes)) {
			const column = (this.gridComponent()?.columns.toArray() as ColumnComponent[]).find(c => c.field == field)
			if (column) {
				if (changes[field] == 'remove') column.hidden = false;
				else if (changes[field] == 'add') column.hidden = true;
			}
		}

		//
		// There seems to be a bug, at least for now, with the Kendo grid that focuses the right-most
		// column header upon grouping.  This forces the horizontal scrollbar all the way to the right.
		// This is an unexpected and jarring experience. To overcome this, we force the focus to the
		// left-most column header.
		//
		setTimeout(() => {
			gridComponent?.focusCell(0, 0);
		}, 0);
	}
}