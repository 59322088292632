// Angular import
import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonIconTextWidget } from '@eforall/common';
import { DashboardMenuItem } from '../../dashboard-frame-config';


@Component({
	selector: 'dashboard-menu-item-part',
	standalone: true,
	imports: [CommonIconTextWidget, RouterModule, CommonModule],
	templateUrl: './dashboard-menu-item.part.html',
	styleUrls: ['./dashboard-menu-item.part.scss']
})
export class DashboardMenuItemPart implements OnInit {
	public readonly item = input.required<DashboardMenuItem>();
	private router = inject(Router);

	ngOnInit() {
		const currentUrl = this.router.url;
		const link = `a.nav-link[href$='${currentUrl}']`;
		const ele = document.querySelector(link);
		if (ele !== null && ele !== undefined) {
			const parent = ele.parentElement;
			if (parent) {
				parent.classList.add('active');
			}
		}
	}
}
