import { Injectable, computed, inject, signal } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { FuncData } from '@interfaces';
import { FuncService } from '..';
import { appDataEquality } from './app-data-equality';
import { EMPTY_DATA } from './empty-data';
import { processData } from './process-data/process-data';


@Injectable({ providedIn: 'root' })
export class AppService {

	private util = inject(UtilityService);
	private readonly func = inject(FuncService);

	private readonly _data = signal<FuncData>(EMPTY_DATA, { equal: appDataEquality })
	public readonly data = computed(() => processData(this._data(), this.util));


	public async loadData(): Promise<void> {
		this._data.set(await this.func.getData());
	}


	public async clearData(): Promise<void> {
		this._data.set(EMPTY_DATA);
	}


	public async update<T>(action: (payload: T) => Promise<FuncData>, payload: T): Promise<void> {
		this._data.set(await action(payload));
	}
}