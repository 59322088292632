<dialog-frame header="Filters" [actions]="actions()" icon="fa-filter" alertType='editor'
	(closed)="activeModal.close($event)">

	<kendo-filter [value]="originalFilters()" (valueChange)="onFiltersChange($event)">

		@for(exp of filterExpressions(); track exp){
		<kendo-filter-field [field]="exp.field" [title]="exp.title ||''" [editor]="exp.editor"></kendo-filter-field>
		}
	</kendo-filter>

</dialog-frame>