import { Staff } from "@app-interfaces";
import { UtilityService } from '@eforall/common';
import { GridColumn, buildColumn } from '@eforall/dashboard';


export function getColumns(util: UtilityService) {

	const columns: GridColumn<Staff, unknown>[] = [
		buildColumn(util, 'Id', row => row.staffId, { header: 'staffId' }),
		buildColumn(util, 'Status', row => row.status),
		buildColumn(util, 'FirstName', row => row.firstName, { hidden: true }),
		buildColumn(util, 'LastName', row => row.lastName, { hidden: true }),
		buildColumn(util, 'FullName', row => row.fullName),
		buildColumn(util, 'Id', row => row.jobRole?.jobTrackId, { header: 'jobTrackId' }),
		buildColumn(util, 'Text', row => row.jobRole?.jobTrack.name, { hidden: true, header: 'Job Track' }),
		buildColumn(util, 'Id', row => row.jobRoleId, { header: 'jobRoleId' }),
		buildColumn(util, 'Text', row => row.jobRole?.en, { hidden: true, header: 'Job Role' }),
		buildColumn(util, 'Type', row => row.staffType),
		buildColumn(util, 'Text', row => row.orgUnitPath?.substring(1).split('/').join(' / '), { header: 'Org Unit Path', width: 150, hidden: true }),
		buildColumn(util, 'Id', row => row.managerStaffId, { header: 'managerStaffId' }),
		buildColumn(util, 'FullName', row => row.manager ? row.manager.fullName : undefined, { header: 'Manager' }),
		buildColumn(util, 'Number', row => row.depth, { hidden: true, header: 'Depth', headerTooltip: 'Depth in the Org Tree' }),
		buildColumn(util, 'Number', row => row.directs || undefined, { hidden: true, header: 'Directs', headerTooltip: 'Count of staff that report directly to this person' }),
		buildColumn(util, 'Number', row => row.indirects || undefined, { hidden: true, header: 'Indirects', headerTooltip: 'Count of staff that report indirectly to this person' }),
		buildColumn(util, 'Id', row => row.departmentId, { header: 'departmentId' }),
		buildColumn(util, 'Text', row => row.department?.name, { header: 'Department' }),
		buildColumn(util, 'Id', row => row.locationId, { header: 'locationId' }),
		buildColumn(util, 'Text', row => row.location?.en, { hidden: true, header: 'Location' }),
		buildColumn(util, 'Id', row => row.locationId, { header: 'pronounsId' }),
		buildColumn(util, 'Text', row => row.pronouns?.en, { hidden: true, header: 'Pronouns' }),
		buildColumn(util, 'Phone', row => row.mobilePhone),
		buildColumn(util, 'Email', row => row.email, { hidden: true }),
		buildColumn(util, 'DateTime', row => util.date.fromUTC(row.updatedUTC), { header: 'Updated', hidden: true }),
	];

	/**
		isAdmin: boolean,
		isDelegatedAdmin: boolean,
		isEnrolledIn2Sv: boolean,
		isEnforcedIn2Sv: boolean,
		lastLoginUTC: number | undefined,
		isBipoc: boolean,
		birthDay: string | undefined,
		isBirthDayShared: boolean,
		startDay: string | undefined,
		recoveryEmail: string | undefined,
		recoveryPhone: string | undefined,
	*/

	return columns;

}